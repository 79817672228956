import { axiosInstance } from "../../index";

const getListCategory = async () => {
  try {
    const res = await axiosInstance.get("/customer/list-categories");
    const data = res && res.data;
    return { data };
  } catch (err: any) {
    const errRes = (err && err.response) || "Network Error";
    return { errRes };
  }
};
const getListSubCategory = async (id: string) => {
  try {
    const res = await axiosInstance.get(`/customer/list-subcategories/${id}`);
    const data = res && res.data;
    return { data };
  } catch (err: any) {
    const errRes = (err && err.response) || "Network Error";
    return { errRes };
  }
};

const getProducts = async (catID: any) => {
  let url = catID
    ? `/customer/list-products/${catID}`
    : `/customer/list-products`;
  try {
    const res = await axiosInstance.get(url);
    const data = res && res.data;
    return { data };
  } catch (err: any) {
    const errRes = (err && err.response) || "Network Error";
    return { errRes };
  }
};

const getFilteredProducts = async (catID: any, subCatId: any) => {
  let url = subCatId
    ? `/customer/list-products/${catID}/${subCatId}`
    : `/customer/list-products/${catID}`;
  try {
    const res = await axiosInstance.get(url);
    const data = res && res.data;
    return { data };
  } catch (err: any) {
    const errRes = (err && err.response) || "Network Error";
    return { errRes };
  }
};
const getProductDetails = async (id: any) => {
  try {
    const res = await axiosInstance.get(`/customer/get-product-details/${id}`);
    const data = res && res.data;
    return { data };
  } catch (err: any) {
    const errRes = (err && err.response) || "Network Error";
    return { errRes };
  }
};

export {
  getListCategory,
  getProducts,
  getProductDetails,
  getListSubCategory,
  getFilteredProducts,
};
