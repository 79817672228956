export const ADD_CART = "ADD_CART";
export const DELETE_CART = "DELETE_CART";
export const UPDATE_CART = "UPDATE_CART";
export const EMPTY_CART = "EMPTY_CART";

export const ADD_CART_ACTION = (data: any) => {
  return {
    type: ADD_CART,
    payload: data,
  };
};

export const DELETE_CART_ACTION = (data: any) => {
  return {
    type: DELETE_CART,
    payload: data,
  };
};

export const UPDATE_CART_ACTION = (data: any) => {
  return {
    type: UPDATE_CART,
    payload: data,
  };
};

export const EMPTY_CART_ACTION = () => {
  return {
    type: EMPTY_CART,
    payload: []
  }
}
