import { axiosInstance } from "../../index";

interface Login {
  email: string;
  password: string;
  company_id: string;
}

interface CheckEmail {
  email: string;
  company_id: string | number; // Allow both string and number for company_id
}

interface RestPass {
  otp: string;
  encrypted_data: string;
  email: string;
  password: string;
}

interface Sign_up {
  first_name: string,
  last_name: string,
  email: string,
  password: string,
  company_id: string,
  isHR: boolean
}

interface Forgot_Pass {
  email: string,
  company_id: string
}
interface twostepCodeData {
  code: number,
  hashedOtp: string,
}

const login = async (payload: Login) => {
  try {
    const res = await axiosInstance.post("/customer/sign-in", payload);
    const data = res && res.data;
    return { data };
  } catch (err: any) {
    const errRes = (err && err.response) || "Network Error";
    return { errRes };
  }
};

const verifyTwoStepCode = async (payload: twostepCodeData) => {
  try {
    const res = await axiosInstance.post("/customer/verifyCode", payload);
    console.log(res.data, "response")
    const data = res && res.data;
    return { data };
  } catch (err: any) {
    console.log(err.response.data.message)
    const errRes = (err && err.response?.data?.message) || "Network Error";
    return { errRes };
  }
};



const checkEmail = async (payload: CheckEmail) => {
  try {
    const res = await axiosInstance.post("/customer/auth-login", payload);
    const data = res && res.data;
    return { data };
  } catch (err: any) {
    const errRes = (err && err.response) || "Network Error";
    return { errRes };
  }
};

const resetpassword = async (payload: RestPass) => {
  try {
    const res = await axiosInstance.post(process.env.REACT_APP_BASE_URL + "customer/reset-password", payload);
    const data = res && res.data;
    return { data };
  } catch (err: any) {
    const errRes = (err && err.response) || "Network Error";
    return { errRes };
  }
};

const forgotpassword = async (payload: any) => {
  try {
    const res = await axiosInstance.post("/customer/send-password-reset-link", payload);
    console.log(res);

    const data = res && res.data;
    return { data };
  } catch (err: any) {
    const errRes = (err && err.response) || "Network Error";
    return { errRes };
  }
};

const changepassword = async (payload: any) => {
  try {
    const res = await axiosInstance.post("/customer/change-password", payload);
    const data = res && res.data;
    return { data };
  } catch (err: any) {
    const errRes = (err && err.response) || "Network Error";
    return { errRes };
  }
};
const verify_token = async (payload: any) => {
  try {
    const res = await axiosInstance.post("/customer/verify-token", payload);
    const data = res && res.data;
    return { data };
  } catch (err: any) {
    const errRes = (err && err.response) || "Network Error";
    return { errRes };
  }
};

const verify_account = async (payload: any) => {
  try {
    const res = await axiosInstance.post("/customer/verify-account", payload);
    const data = res && res.data;
    return { data };
  } catch (err: any) {
    const errRes = (err && err.response) || "Network Error";
    return { errRes };
  }
};

const sign_up = async (payload: Sign_up) => {
  try {
    const res = await axiosInstance.post("/customer/self-sign-up", payload);
    const data = res && res.data;
    return { data };
  } catch (err: any) {
    const errRes = (err && err.response) || "Network Error";
    return { errRes };
  }
};

export { login, resetpassword, forgotpassword, changepassword, verify_account, verify_token, sign_up, checkEmail, verifyTwoStepCode };
