import React, { FC, useEffect } from "react";
import { Link } from "react-router-dom";
import NcImage from "shared/NcImage/NcImage";
import LikeButton from "./LikeButton";
import Prices from "./Prices";
import { ArrowsPointingOutIcon } from "@heroicons/react/24/outline";
import { Product, PRODUCTS } from "data/data";
import { StarIcon } from "@heroicons/react/24/solid";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import BagIcon from "./BagIcon";
import toast from "react-hot-toast";
import { Transition } from "@headlessui/react";
import ModalQuickView from "./ModalQuickView";
import ProductStatus from "./ProductStatus";
import Skeleton from "react-loading-skeleton";

export interface ProductCardProps {
  className?: string;
  data?: any;
  isLiked?: boolean;
}

const CategoryCard: FC<ProductCardProps> = ({
  className = "",
  data,
  isLiked,
}) => {
  const { name, image } = data;
  const [showModalQuickView, setShowModalQuickView] = React.useState(false);

  return (
    <>
      {data ? (
        <div
          className={`nc-ProductCard relative flex flex-col bg-transparent ${className}`}
          data-nc-id="ProductCard"
        >
          <Link
            to={`/page-collection?id=${data?._id}&name=${data.name}&description=${data.description}`}
            className="absolute inset-0"
          ></Link>

          <div className="relative flex-shrink-0 bg-slate-50 dark:bg-slate-300 rounded-3xl overflow-hidden z-1 group">
            <Link
              to={`/page-collection?id=${data?._id}&name=${data.name}&description=${data.description}`}
              className="block"
            >
              <NcImage
                containerClassName="flex aspect-w-11 aspect-h-12 w-full h-0"
                src={image}
                className="object-cover w-full h-full drop-shadow-xl"
              />
            </Link>
          </div>
          <div className="space-y-4 text-center px-2.5 pt-5 pb-2.5">
            <div>
              <h2
                className={`nc-ProductCard__title text-base  text-xl font-semibold transition-colors`}
              >
                {name}
              </h2>
            </div>
          </div>
        </div>
      ) : (
        <div className="md:w-[300px] w-[250px] ">
          <Skeleton className="md:h-[250px] h-[200px]  rounded-3xl " />
          <div className="text-center mt-3">
            <Skeleton width={"70%"} height={"30px"} />
          </div>
        </div>
      )}

      {/* QUICKVIEW */}
      <ModalQuickView
        show={showModalQuickView}
        onCloseModalQuickView={() => setShowModalQuickView(false)}
      />
    </>
  );
};

export default CategoryCard;
