import React, { FC, useState } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import LikeButton from "components/LikeButton";
import AccordionInfo from "../../containers/ProductDetailPage/AccordionInfo";
import { StarIcon } from "@heroicons/react/24/solid";
import BagIcon from "components/BagIcon";
import NcInputNumber from "components/NcInputNumber";
import { PRODUCTS } from "data/data";
import {
    NoSymbolIcon,
    ClockIcon,
    SparklesIcon,
} from "@heroicons/react/24/outline";
import IconDiscount from "components/IconDiscount";
import Prices from "components/Prices";
import toast from "react-hot-toast";
import SectionSliderProductCard from "components/SectionSliderProductCard";
import detail1JPG from "images/products/detail1.jpg";
import detail2JPG from "images/products/detail2.jpg";
import detail3JPG from "images/products/detail3.jpg";
import ReviewItem from "components/ReviewItem";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import SectionPromo2 from "components/SectionPromo2";
import NotifyAddTocart from "components/NotifyAddTocart";
import SectionHero2 from "components/SectionHero/SectionHero2";
import ProductImageCarousel from "../../containers/ProductDetailPage/ProductImageCarousel";
import MyVoucherAddressField from "../VoucherDetails/MyVoucherAddressField";

export interface ProductDetailPageProps {
    className?: string;
}

const ProductDetailPage: FC<ProductDetailPageProps> = ({ className = "" }) => {
    const { sizes, variants, status, allOfSizes } = PRODUCTS[0];
    const LIST_IMAGES_DEMO = [detail1JPG, detail2JPG, detail3JPG];

    const [variantActive, setVariantActive] = React.useState(0);
    const [sizeSelected, setSizeSelected] = React.useState(sizes ? sizes[0] : "");
    const [qualitySelected, setQualitySelected] = React.useState(1);
    const [isOpenModalViewAllReviews, setIsOpenModalViewAllReviews] =
        useState(false);

    const notifyAddTocart = () => {
        toast.custom(
            (t) => (
                <NotifyAddTocart
                    productImage={LIST_IMAGES_DEMO[0]}
                    qualitySelected={qualitySelected}
                    show={t.visible}
                    sizeSelected={sizeSelected}
                    variantActive={variantActive}
                />
            ),
            { position: "top-right", id: "nc-product-notify", duration: 3000 }
        );
    };

    //
    const renderVariants = () => {
        if (!variants || !variants.length) {
            return null;
        }

        return (
            <div>
                <label htmlFor="">
                    <span className="text-sm font-medium">
                        Color:
                        <span className="ml-1 font-semibold">
                            {variants[variantActive].name}
                        </span>
                    </span>
                </label>
                <div className="flex space-x-1">
                    {variants.map((variant, index) => (
                        <div
                            key={index}
                            onClick={() => setVariantActive(index)}
                            className={`relative w-6 h-6 rounded-full overflow-hidden z-10 border cursor-pointer ${variantActive === index
                                ? getBorderClass(variant.color)
                                : "border-transparent"
                                }`}
                            title={variant.name}
                        >
                            <div
                                className={`absolute inset-0.5 rounded-full z-0 ${variant.color}`}
                            ></div>
                        </div>
                    ))}
                </div>
            </div>
        );
    };
    const getBorderClass = (Bgclass = "") => {
        if (Bgclass.includes("red")) {
            return "border-red-500";
        }
        if (Bgclass.includes("violet")) {
            return "border-violet-500";
        }
        if (Bgclass.includes("orange")) {
            return "border-orange-500";
        }
        if (Bgclass.includes("green")) {
            return "border-green-500";
        }
        if (Bgclass.includes("blue")) {
            return "border-blue-500";
        }
        if (Bgclass.includes("sky")) {
            return "border-sky-500";
        }
        if (Bgclass.includes("yellow")) {
            return "border-yellow-500";
        }
        return "border-transparent";
    };

    const renderSizeList = () => {
        if (!allOfSizes || !sizes || !sizes.length) {
            return null;
        }
        return (
            <div>
                <div className="flex justify-between font-medium text-sm">
                    <label htmlFor="">
                        <span className="">
                            Size:
                            <span className="ml-1 font-semibold">{sizeSelected}</span>
                        </span>
                    </label>
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="##"
                        className="text-primary-6000 hover:text-primary-500"
                    >
                        See sizing chart
                    </a>
                </div>
                <div className="grid grid-cols-5 sm:grid-cols-7 gap-2 mt-3">
                    {allOfSizes.map((size, index) => {
                        const isActive = size === sizeSelected;
                        const sizeOutStock = !sizes.includes(size);
                        return (
                            <div
                                key={index}
                                className={`relative h-10 sm:h-11 rounded-2xl border flex items-center justify-center 
                text-sm sm:text-base uppercase font-semibold select-none overflow-hidden z-0 ${sizeOutStock
                                        ? "text-opacity-20 dark:text-opacity-20 cursor-not-allowed"
                                        : "cursor-pointer"
                                    } ${isActive
                                        ? "bg-primary-6000 border-primary-6000 text-white hover:bg-primary-6000"
                                        : "border-slate-300 dark:border-slate-600 text-slate-900 dark:text-slate-200 hover:bg-neutral-50 dark:hover:bg-neutral-700"
                                    }`}
                                onClick={() => {
                                    if (sizeOutStock) {
                                        return;
                                    }
                                    setSizeSelected(size);
                                }}
                            >
                                {size}
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    };

    const renderStatus = () => {
        if (!status) {
            return null;
        }
        const CLASSES =
            "absolute top-3 left-3 px-2.5 py-1.5 text-xs bg-white dark:bg-slate-900 nc-shadow-lg rounded-full flex items-center justify-center text-slate-700 text-slate-900 dark:text-slate-300";
        if (status === "New in") {
            return (
                <div className={CLASSES}>
                    <SparklesIcon className="w-3.5 h-3.5" />
                    <span className="ml-1 leading-none">{status}</span>
                </div>
            );
        }
        if (status === "50% Discount") {
            return (
                <div className={CLASSES}>
                    <IconDiscount className="w-3.5 h-3.5" />
                    <span className="ml-1 leading-none">{status}</span>
                </div>
            );
        }
        if (status === "Sold Out") {
            return (
                <div className={CLASSES}>
                    <NoSymbolIcon className="w-3.5 h-3.5" />
                    <span className="ml-1 leading-none">{status}</span>
                </div>
            );
        }
        if (status === "limited edition") {
            return (
                <div className={CLASSES}>
                    <ClockIcon className="w-3.5 h-3.5" />
                    <span className="ml-1 leading-none">{status}</span>
                </div>
            );
        }
        return null;
    };

    const renderSectionContent = () => {
        return (
            <div className="space-y-7 2xl:space-y-8">
                <h2 className="text-2xl sm:text-3xl font-semibold">Jute Bags</h2>
                {/* ---------- 5 ----------  */}
                <AccordionInfo />

                {/* ---------- 6 ----------  */}
                <div className="hidden xl:block">{/* <Policy /> */}</div>
            </div>
        );
    };

    const renderDetailSection = () => {
        return (
            <div className="">
                <h2 className="text-2xl font-semibold">Product Details</h2>
                <div className="prose prose-sm sm:prose dark:prose-invert sm:max-w-4xl mt-7">
                    <p>
                        The patented eighteen-inch hardwood Arrowhead deck --- finely
                        mortised in, makes this the strongest and most rigid canoe ever
                        built. You cannot buy a canoe that will afford greater satisfaction.
                    </p>
                    <p>
                        The St. Louis Meramec Canoe Company was founded by Alfred Wickett in
                        1922. Wickett had previously worked for the Old Town Canoe Co from
                        1900 to 1914. Manufacturing of the classic wooden canoes in Valley
                        Park, Missouri ceased in 1978.
                    </p>
                    <ul>
                        <li>Regular fit, mid-weight t-shirt</li>
                        <li>Natural color, 100% premium combed organic cotton</li>
                        <li>
                            Quality cotton grown without the use of herbicides or pesticides -
                            GOTS certified
                        </li>
                        <li>Soft touch water based printed in the USA</li>
                    </ul>
                </div>
            </div>
        );
    };

    return (
        <div className={`nc-ProductDetailPage ${className}`}>
            {/* MAIn */}
            <main className="container mt-5 lg:mt-11">
                <div className=" h-full lg:flex">
                    <div className="w-full  lg:w-[55%]">
                        <ProductImageCarousel />
                    </div>
                    <div className="w-full lg:w-[45%] pt-10 lg:pt-0 lg:pl-7 xl:pl-9 2xl:pl-10">
                        {renderSectionContent()}
                    </div>
                </div>

                {/* DETAIL AND REVIEW */}
                <div className="mt-12 sm:mt-16 space-y-10 sm:space-y-16">
                    {/* {renderDetailSection()} */}
                    {/* <MyVoucherAddressField/> */}

                    <hr className="border-slate-200 dark:border-slate-700" />
                    {/* OTHER SECTION */}

                </div>
            </main>
        </div>
    );
};

export default ProductDetailPage;
