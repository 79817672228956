import { Helmet } from "react-helmet-async";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Pagination from "shared/Pagination/Pagination";
import { Popover, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/24/solid";
import { useState, Fragment, useEffect } from "react";
import ButtonThird from "shared/Button/ButtonThird";
import Checkbox from "shared/Checkbox/Checkbox";
import { Link } from "react-router-dom";
import CatalogueCreateModal from "../../components/CatalogueModal";
import { getCatalogue, deleteCatalogue, downloadCatalog } from "network/services/HR";
import toast from "react-hot-toast";
import ActionModal from "components/ActionModal";

const HrCatalogue = () => {
  const [tableHeaders, setTableHeader] = useState([
    { name: "SL No", key: "data1" },
    { name: "Catalogue Name", key: "name" },
    { name: "Created On", key: "createdAt" },
    { name: "Action", key: "data3" },
  ]);

  const [showModal, setShowModal] = useState(false);
  const [categoriesState, setCategoriesState] = useState<string[]>([]);
  const [catalogue, setCatalogue] = useState<any>(null);
  const [isEdit, setIsEdit] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [selectedName, setSelectedName] = useState<any>();
  const [loading, setLoading] = useState(false)

  const [searchInput, setSearchInput] = useState("");


  const renderMagnifyingGlassIcon = () => {
    return (
      <svg
        width={22}
        height={22}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11.5 21C16.7467 21 21 16.7467 21 11.5C21 6.25329 16.7467 2 11.5 2C6.25329 2 2 6.25329 2 11.5C2 16.7467 6.25329 21 11.5 21Z"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M22 22L20 20"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  };

  const renderSearchForm = () => {
    const handleSearchInputChange = (event: any) => {
      setSearchInput(event.target.value);
    };

    return (
      <div className="flex-1 w-full py-2 text-slate-900 dark:text-slate-100">
        <div className="bg-slate-50 dark:bg-slate-800 flex items-center space-x-1.5 px-5 h-full rounded">
          {renderMagnifyingGlassIcon()}
          <input
            type="text"
            placeholder="Search by name of the catalog"
            className="border-none bg-transparent focus:outline-none focus:ring-0 w-full text-base"
            autoFocus
            value={searchInput}
            onChange={handleSearchInputChange}
          />
        </div>
      </div>
    );
  };




  const handleChangeCategories = (checked: boolean, name: string) => {
    checked
      ? setCategoriesState([...categoriesState, name])
      : setCategoriesState(categoriesState.filter((i) => i !== name));
  };
  const renderXClear = () => {
    return (
      <span className="flex-shrink-0 w-4 h-4 rounded-full bg-primary-500 text-white flex items-center justify-center ml-3 cursor-pointer">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-3 w-3"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fillRule="evenodd"
            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
            clipRule="evenodd"
          />
        </svg>
      </span>
    );
  };
  const renderTabsCategories = () => {
    return (
      <Popover className="relative">
        {({ open, close }) => (
          <>
            <Popover.Button
              className={`flex items-center justify-center px-4 py-3 text-sm rounded-full border focus:outline-none select-none
               ${open
                  ? "!border-primary-500 "
                  : "border-neutral-300 dark:border-neutral-700"
                }
                ${!!categoriesState.length
                  ? "!border-primary-500 bg-primary-50 text-primary-900"
                  : "border-neutral-300 dark:border-neutral-700 text-neutral-700 dark:text-neutral-300 hover:border-neutral-400 dark:hover:border-neutral-500"
                }
                `}
            >
              <svg className={`w-6 h-6`} viewBox="0 0 24 24" fill="none">
                <path
                  d="M14.3201 19.07C14.3201 19.68 13.92 20.48 13.41 20.79L12.0001 21.7C10.6901 22.51 8.87006 21.6 8.87006 19.98V14.63C8.87006 13.92 8.47006 13.01 8.06006 12.51L4.22003 8.47C3.71003 7.96 3.31006 7.06001 3.31006 6.45001V4.13C3.31006 2.92 4.22008 2.01001 5.33008 2.01001H18.67C19.78 2.01001 20.6901 2.92 20.6901 4.03V6.25C20.6901 7.06 20.1801 8.07001 19.6801 8.57001"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M16.07 16.52C17.8373 16.52 19.27 15.0873 19.27 13.32C19.27 11.5527 17.8373 10.12 16.07 10.12C14.3027 10.12 12.87 11.5527 12.87 13.32C12.87 15.0873 14.3027 16.52 16.07 16.52Z"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M19.87 17.12L18.87 16.12"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>

              <span className="ml-2">Filter</span>
              {!categoriesState.length ? (
                <ChevronDownIcon className="w-4 h-4 ml-3" />
              ) : (
                <span onClick={() => setCategoriesState([])}>
                  {renderXClear()}
                </span>
              )}
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute z-40 w-screen max-w-sm px-4 mt-3 left-0 sm:px-0 lg:max-w-md">
                <div className="overflow-hidden rounded-2xl shadow-xl bg-white dark:bg-neutral-900 border border-neutral-200 dark:border-neutral-700">
                  <div className="relative flex flex-col px-5 py-6 space-y-5">
                    <Checkbox
                      name="All Categories"
                      label="All Categories"
                      defaultChecked={categoriesState.includes(
                        "All Categories"
                      )}
                      onChange={(checked) =>
                        handleChangeCategories(checked, "All Categories")
                      }
                    />
                    <div className="w-full border-b border-neutral-200 dark:border-neutral-700" />
                  </div>
                  <div className="p-5 bg-neutral-50 dark:bg-neutral-900 dark:border-t dark:border-neutral-800 flex items-center justify-between">
                    <ButtonThird
                      onClick={() => {
                        close();
                        setCategoriesState([]);
                      }}
                      sizeClass="px-4 py-2 sm:px-5"
                    >
                      Clear
                    </ButtonThird>
                    <ButtonPrimary
                      onClick={close}
                      sizeClass="px-4 py-2 sm:px-5"
                    >
                      Apply
                    </ButtonPrimary>
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    );
  };

  const renderAction = (data: any) => {
    return (
      <Popover className="relative">
        {({ open, close }) => (
          <>
            <Popover.Button>
              <span className="ml-2" style={{ fontSize: '30px' }}>...</span>
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute z-40  px-2 mt-3 left-20 sm:px-0 ">
                <div className="overflow-hidden rounded-2xl shadow-xl bg-white dark:bg-neutral-900 border border-neutral-200 dark:border-neutral-700">
                  <div className="relative flex flex-col px-2 py-2 space-y-2">
                    <div className="  hover:bg-neutral-300  px-6" onClick={() => { downloadCatalogFromAPI(data?._id) }}>Download</div>
                    <div
                      onClick={() => {
                        setIsEdit(true);
                        setShowModal(true);
                        setSelectedName(data);
                      }}
                      className=" hover:bg-neutral-300"
                    >
                      Edit
                    </div>
                    <div
                      onClick={() => {
                        setIsDelete(true);
                        setSelectedName(data);
                      }}
                      className=" hover:bg-neutral-300"
                    >
                      Delete
                    </div>
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    );
  };

  const getCataloguesFromAPI = async () => {
    toast.loading("Retreiving catalogues")
    try {
      const resp = await getCatalogue();
      toast.dismiss()

      if (!resp?.data.message) {
        setCatalogue(resp?.data);
      } else {
        setCatalogue([])
        toast.error(resp?.data.message);
      }
    } catch (error) { }
  };
  function formatDate(dateString: string) {
    const date = new Date(dateString);

    const day = date.getDate();
    const month = date.getMonth() + 1; // Month is zero-based
    const year = date.getFullYear() % 100; // Get last two digits of the year

    // Ensure leading zeros if necessary
    const formattedDay = day < 10 ? "0" + day : day;
    const formattedMonth = month < 10 ? "0" + month : month;
    const formattedYear = year < 10 ? "0" + year : year;

    // Return the formatted date string
    return `${formattedDay}-${formattedMonth}-${formattedYear}`;
  }

  const deleteCatalogFromAPI = async () => {
    let obj = {
      name: selectedName?.name
    }
    setLoading(true)
    try {
      const resp = await deleteCatalogue(obj, selectedName?._id);
      if (resp?.data) {
        toast.success(resp?.data.message, { duration: 2000 });
        setTimeout(() => {
          setIsDelete(false);
          setLoading(false)
          getCataloguesFromAPI();
        }, 2000)
      } else {
        setLoading(false)
        toast.error(resp?.data.message);
      }
    } catch (error) {
      setLoading(false)
    }
  }

  const downloadCatalogFromAPI = async (id: string) => {
    try {
      const loadingToast = toast.loading("Please wait, the pdf file could be large to download.");
      const resp = await downloadCatalog(id);
      toast.dismiss(loadingToast);
      if (resp?.data) {
        // Decode base64 string
        console.log(resp.data)
        // Convert Base64 to Uint8Array
        var bytes = Uint8Array.from(atob(resp.data), c => c.charCodeAt(0));

        // Create Blob from Uint8Array
        var blob = new Blob([bytes], { type: 'application/pdf' });

        // Create object URL from Blob
        var url = URL.createObjectURL(blob);

        // Create a link element
        var a = document.createElement('a');
        a.href = url;
        a.download = `${id}.pdf`; // Change the filename as needed

        // Append the link to the body
        document.body.appendChild(a);

        // Simulate click on the link
        a.click();

        // Remove the link from the body
        document.body.removeChild(a);
        toast.success("PDF is loaded successfully.");
      } else {
        toast.error(resp?.data.message);
      }
    } catch (error) { }
  }


  useEffect(() => {
    getCataloguesFromAPI();
  }, []);

  const filteredCatalogue = catalogue?.filter((item: any) =>
    item.name.toLowerCase().includes(searchInput.toLowerCase())
  );


  return (
    <div className="nc-PageHome  relative overflow-hidden">
      <Helmet>
        <title>Hr Points</title>
      </Helmet>

      <CatalogueCreateModal
        show={showModal}
        isEdit={isEdit}
        editData={selectedName}
        onCloseModalReportItem={() => {
          setSelectedName({ ...selectedName, name: "" })
          setShowModal(false);
          getCataloguesFromAPI();
        }}
      />

      <ActionModal
        show={isDelete}
        primaryTitle="Delete"
        message={`Delete catalog ${selectedName?.name}`}
        secondaryTitle={"Cancel"}
        onCloseModalReportItem={() => {
          if (!loading)
            setIsDelete(false);
        }}
        nextAction={() => {
          if (!loading)
            deleteCatalogFromAPI();
        }}
      />

      <div className="container">
        <div className="mb-12 sm:mb-16">
          <div className="block mt-3 sm:mt-5 text-xs sm:text-sm font-medium text-slate-700 dark:text-slate-400">
            <Link to={"/hr-dashboard"} className="">
              My Menu
            </Link>
            <span className="text-xs mx-1 sm:mx-1.5">/</span>
            <span className="underline">Catalog</span>
          </div>
        </div>
        <div className="mt-6  rounded mb-24 lg:mt-4 border border-slate ">
          <div className="bg-white rounded ">
            <div className=" flex items-center justify-between pb-4 m-4 pt-4">
              <div className="flex w-full  mr-5 items-center">
                {renderSearchForm()}
                {/* <span className="pl-4 pr-4">{renderTabsCategories()}</span> */}
                <ButtonPrimary
                  onClick={() => {
                    setIsEdit(false)
                    setShowModal(true)
                  }}
                  className="sm:!px-7 px-4 shadow-none "
                >
                  Create Catalog
                </ButtonPrimary>
              </div>
            </div>

            {
              (catalogue) ? (

                <table className="min-w-full">
                  <thead
                    className="bg-neutral-200"
                    style={{ position: "sticky", top: 0 }}
                  >
                    <tr className="text-left ">
                      <th className=" shadow  text-primary-50 px-4 py-4">
                        {"Sl No"}
                      </th>
                      <th className=" shadow  text-primary-50 px-4 py-4">
                        {"Name"}
                      </th>
                      <th className=" shadow  text-primary-50 px-4 py-4">
                        {"Created On"}
                      </th>
                      <th className=" text-center shadow  text-primary-50 px-4 py-4">
                        {"Action"}
                      </th>
                    </tr>
                  </thead>
                  <tbody style={{ overflow: "auto", maxHeight: "650px" }}>
                    {filteredCatalogue?.length >= 1 &&
                      filteredCatalogue.map((item: any, index: number) => {
                        return (
                          <tr
                            style={{ borderBottom: "1px solid #ccc" }}
                            className=" border-slate hover:bg-neutral-300"
                          >
                            <td className=" border-slate  px-4 py-2">{index + 1}</td>
                            <td className=" border-slate  px-4 py-2">
                              {item?.name}
                            </td>
                            <td className=" border-slate  px-4 py-2">
                              {" "}
                              {item?.createdAt ? formatDate(item.createdAt) : ""}
                            </td>
                            <td className=" cursor-pointer text-center border-slate  px-4 py-2">
                              <span>{renderAction(item)}</span>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              ) : (
                <div className="min-h-[20vh] flex flex-col justify-center">
                  <h2 className="text-center text-2xl font-semibold text-gray-400">No Catalogue Found</h2>
                </div>
              )
            }


            <div className=" pb-4 m-4 pt-4 flex flex-col mt-12 lg:mt-4 space-y-5 sm:space-y-0 sm:space-x-3 sm:flex-row sm:justify-end sm:items-center">
            </div>
          </div>
        </div>
      </div>
    </div>
  )
};

export default HrCatalogue;
