import { Helmet } from "react-helmet-async";
import HrDashboardCards from "./dashboadrCards";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import { isAuth } from "network/helper";
import { useEffect } from "react";

const HrDashboard = () => {
  useEffect(() => {
    if (!isAuth() && !isAuth()?.isHr) {
      window.location.href = "/login";
    }
  }, []);
  return (
    <div className="  container mt-6 mb-12 lg:mt-12 ">
      <Helmet>
        <title>Hr Portal</title>
      </Helmet>

      <div className="relative py-24 mb-32 lg:py-32">
        <BackgroundSection />
        <HrDashboardCards />
      </div>
    </div>
  );
};

export default HrDashboard;
