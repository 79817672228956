import CommonLayout from "./CommonLayout";
import { useEffect, useState } from 'react';
import { cancelEcommOrder, getEcommOrders, getPointOrders, getVoucherOrders } from "network/services/order";
import { getLocalStorage } from "network/helper";
import toast from "react-hot-toast";
import OrderList from "./OrderList";

interface Orders {
  Voucher: Array<any>,
  Ecommerce: Array<any>,
  Point: Array<any>,
}

const AccountOrder = () => {
  const [activeTab, setActiveTab] = useState<string>("All")
  const [company, setCompany] = useState<string>("")
  const [orders, setOrders] = useState<any>({
    Voucher: [],
    Ecommerce: [],
    Point: []
  })
  const [isEcommerceEnabled, setIsEcommerceEnabled] = useState(false)
  const [allOrders, setAllOrders] = useState<Array<any>>([])

  const fetchOrders = async () => {
    toast.loading("Fetching Orders...")
    const v_res = await getVoucherOrders()
    if (v_res.data && Array.isArray(v_res.data))
      setOrders((state: any) => ({ ...state, Voucher: v_res.data }))

    const e_res = await getEcommOrders()
    if (e_res.data && Array.isArray(e_res.data))
      setOrders((state: any) => ({ ...state, Ecommerce: e_res.data }))

    const p_res = await getPointOrders()
    if (p_res.data && Array.isArray(p_res.data))
      setOrders((state: any) => ({ ...state, Point: p_res.data }))

    toast.dismiss()
    setAllOrders(() => {
      let tempOrder: Array<any> = []
      if (Array.isArray(v_res.data)) tempOrder.push(...v_res.data)
      if (Array.isArray(e_res.data)) tempOrder.push(...e_res.data)
      if (Array.isArray(p_res.data)) tempOrder.push(...p_res.data)

      tempOrder.sort((a, b) => {
        const a_date = (new Date(a.orderedOn)).getTime()
        const b_date = (new Date(b.orderedOn)).getTime()
        return b_date - a_date
      })
      return tempOrder;
    })
  }

  const getCompany = async () => {
    const comp = getLocalStorage("company")
    const name: string = comp.name
    setIsEcommerceEnabled(comp.isEcommerceEnabled)
    setCompany(name.toLowerCase().slice(0, 3))
  }

  const handleCancelOrder = async (id: string) => {
    toast.loading("...")
    const res = await cancelEcommOrder(id)
    toast.dismiss()
    if (res.errRes) {
      toast.error(res.errRes.data.message)
      return
    }
    toast.success("Successfully cancelled the order", { duration: 3000 })
    fetchOrders()
  }

  useEffect(() => {
    fetchOrders()
    getCompany()
  }, [activeTab])

  return (
    <div>
      <CommonLayout>
        <div className="space-y-10 sm:space-y-12">
          {/* HEADING */}
          <div className="flex flex-row justify-between">
            <h2 className="text-2xl sm:text-3xl font-semibold">Order History</h2>
            <select value={activeTab} onChange={(e) => setActiveTab(e.target.value)}>
              {/* <option value="All" className="">All</option> */}
              <option value="All" className="">All</option>
              {isEcommerceEnabled && (<option value="Ecommerce" className="">Ecommerce</option>)}
              {isEcommerceEnabled && (<option value="Point" className="">Points</option>)}
              <option value="Voucher" className="">Voucher</option>
            </select>
          </div>
          {
            activeTab == "All" && allOrders.length != 0 && (
              <OrderList
                handleCancelOrder={handleCancelOrder}
                company={company}
                list={allOrders}
              />
            )
          }
          {
            activeTab != "All" && orders[activeTab].length != 0 && (
              <OrderList
                handleCancelOrder={handleCancelOrder}
                company={company}
                list={orders[activeTab]}
              />
            )}
          {
            ((activeTab == "All" && allOrders.length == 0) || (activeTab != "All" && orders[activeTab].length == 0)) && (
              (
                <div className="text-center text-gray-400 font-semibold text-2xl">
                  No orders found
                </div>
              )
            )
          }
        </div>
      </CommonLayout>
    </div>
  );
};

export default AccountOrder;
