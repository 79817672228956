const ProductReducer = (
    state = [],
    action: { type: any; payload: any }
  ) => {
    switch (action.type) {
      case "ADD_PRODUCT":
        const newItems = action.payload.filter(
          (newItem: any) =>
            !state.some((item: any) => item.product?._id === newItem.product?._id)
        );
        return [...state, ...newItems];
      default:
        return state;
    }
  };
  
  export default ProductReducer;
  