import { FC, useEffect, useState } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import BagIcon from "components/BagIcon";
import AccordionInfo from "containers/ProductDetailPage/AccordionInfo";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import { toast } from "react-hot-toast";
import ButtonClose from "shared/ButtonClose/ButtonClose";


interface VoucherPopupProps {
  show: boolean;
  data: any;
  closePopup: () => void;
  selectProduct: (categoryIndex: number, productId: string, entityId: string | null) => void
}

const VoucherPopup: FC<VoucherPopupProps> = ({
  show,
  data,
  closePopup,
  selectProduct,
}) => {
  const isSelected = false
  const { product, categoryIndex } = data
  const {
    name,
    entities,
    description
  } = product

  const [selectedEntity, setSelectedEntity] = useState<string | null>(null)
  const [accordionData, setAccordionData] = useState<Array<any>>([])

  const handleSelectProduct = (temp: string | null) => {
    selectProduct(categoryIndex, product._id, selectedEntity)
    closePopup()
  }


  const renderSizeList = () => {
    if (!entities || !entities?.length) {
      return null;
    }

    return (
      <div className={`inset-x-1 space-x-1.5 flex justify-center`}>
        <select
          className="w-full rounded-xl"
          onChange={(e) => setSelectedEntity(e.target.value)}
        >

          <option value="">Select the size</option>
          {entities.map((size: any, index: any) => {
            return (
              <option
                key={index}
                value={size._id}
              >
                {size?.name}
              </option>
            );
          })}
        </select>
      </div>
    );
  };

  const renderSectionContent = () => {
    return (
      <div className="space-y-8">
        {/* ---------- 1 HEADING ----------  */}

        <div>
          <h2 className="text-2xl font-semibold hover:text-primary-6000 transition-colors">
            <h2>{name}</h2>
          </h2>
        </div>

        {/*  ---------- 4  QTY AND ADD TO CART BUTTON */}
        <div className="flex space-x-3.5">
          {Array.isArray(entities) && entities.length >= 1 && renderSizeList()}

          {isSelected ? (
            <ButtonSecondary className=" border border-slate flex-1 flex-shrink-0">
              {/* <BagIcon className="hidden sm:inline-block w-5 h-5 mb-0.5" /> */}
              <span className="ml-3">Selected</span>
            </ButtonSecondary>
          ) : (
            <ButtonPrimary
              className="flex-1 flex-shrink-0 max-w-[250px]"
              onClick={() => {
                if (selectedEntity != null) {
                  handleSelectProduct(selectedEntity)
                  return
                }
                if (selectedEntity == null) {
                  if (Array.isArray(entities) && entities.length > 1)
                    toast.error("Please select a size")
                  if (Array.isArray(entities) && entities.length == 0)
                    handleSelectProduct(null)
                }
              }}
            >
              <BagIcon className="hidden sm:inline-block w-5 h-5 mb-0.5" />
              <span className="ml-3">Select</span>
            </ButtonPrimary>
          )}
        </div>
        {/*  */}
        <hr className=" border-slate-200 dark:border-slate-700"></hr>
        {/*  */}

        {/* ---------- 5 ----------  */}
        <AccordionInfo
          data={accordionData}
        />

        {/* ---------- 6 ----------  */}
      </div>
    );
  };

  useEffect(() => {
    if (product) {
      const tempAccordionData = []
      if (description) tempAccordionData.push({ name: "Description", content: description })
      setAccordionData(tempAccordionData)
    }
  }, [product])

  return (
    <div className={`${show ? "block" : "hidden"} fixed top-0 left-0 w-screen h-screen z-[40] overflow-hidden`}>
      <div
        onClick={closePopup}
        className="absolute top-0 left-0 w-screen h-screen bg-black/50 z-[-10]"
      ></div>
      <div className="w-full h-full lg:w-[80vw] lg:max-w-[1200px] lg:max-h-[90vh] absolute left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2 bg-white p-6 lg:rounded-xl ">
        <ButtonClose onClick={closePopup} className="absolute right-3 top-3" />
        <div className={`nc-ProductQuickView overflow-y-auto overflow-hidden h-full w-full no-scrollbar`}>
          <div className="lg:flex">
            <div className="w-full lg:w-[50%] ">
              <div className="relative">
                <div className="aspect-w-16 aspect-h-16">
                  <img
                    src={product?.images && product?.images[0]}
                    className="w-full rounded-xl object-cover"
                    alt="product detail 1"
                  />
                </div>
              </div>
              <div className="hidden lg:grid grid-cols-2 gap-3 mt-3 sm:gap-6 sm:mt-6 xl:gap-5 xl:mt-5">
                {product?.images &&
                  product?.images
                    .slice(1, 4)
                    .map((item: string, index: number) => {
                      return (
                        <div key={index} className="aspect-w-3 aspect-h-4">
                          <img
                            src={item}
                            className="w-full rounded-xl object-cover"
                            alt={`product detail ${index + 1}`}
                          />
                        </div>
                      );
                    })}
              </div>
            </div>

            {/* SIDEBAR */}
            <div className="w-full lg:w-[50%] pt-6 lg:pt-5 lg:pl-7 xl:pl-8">
              {renderSectionContent()}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default VoucherPopup