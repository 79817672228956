import { Helmet } from "react-helmet-async";
import MyPointsCard from "./MyPointsCards";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import DynamicTable from "components/CustomTable";
import Pagination from "shared/Pagination/Pagination";
import { Popover, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/24/solid";
import { useState, Fragment } from "react";
import ButtonThird from "shared/Button/ButtonThird";
import Checkbox from "shared/Checkbox/Checkbox";
import Heading2 from "components/Heading/Heading2";
import { getAvailablePoints, getPointsCredited, getPointsUsage } from "network/services/account";
import { useEffect } from "react";
import { toast } from "react-hot-toast";

const HrDashboard = () => {
  const [tableHeaders, setTableHeader] = useState([
    { name: "SL No", key: "sno" },
    { name: "Amount", key: "amount" },
    { name: "Available Amount", key: "availableAmount" },
    { name: "Credited On", key: "createdDate" },
    { name: "Expire Date", key: "expiryDate" },
  ]);

  const [creditedPoints, setCreditedPoints] = useState([]);
  const [usedPoints, setUsedPoints] = useState([])
  const [availablePoints, setAvailablePoints] = useState(0)

  const fetchPointsTransactions = async () => {
    const c_res = await getPointsCredited()
    if (c_res.data && Array.isArray(c_res.data))
      setCreditedPoints(() => {
        return c_res.data.map((item: any, index: number) => {
          const createdDate = new Date(item.createdDate.split('T')[0])
          const expiryDate = new Date(item.expiryDate.split('T')[0])
          return {
            sno: index + 1,
            amount: item.amount,
            availableAmount: item.availableAmount ? item.availableAmount : "0",
            createdDate: createdDate.toLocaleDateString('en-GB', { day: "numeric", month: "short", year: "2-digit" }),
            expiryDate: expiryDate.toLocaleDateString('en-GB', { day: "numeric", month: "short", year: "2-digit" })
          }
        })
      })
    console.log("Credits: ", c_res.data)


    const u_res = await getPointsUsage()
    if (u_res.data && Array.isArray(u_res.data))
      setUsedPoints(() => {
        return u_res.data.map((item: any, index: number) => {
          const formattedDate = new Date(item.usedDate.split('T')[0])
          return {
            sno: index + 1,
            amount: item.amount,
            usedDate: formattedDate.toLocaleDateString('en-GB', { day: "numeric", month: "short", year: "2-digit" })
          }
        })
      })
  }

  const fetchAvailablePoints = async () => {
    toast.loading("Fetching available points")
    const res = await getAvailablePoints()
    toast.dismiss();
    if (res.data && !res.data.message) {
      setAvailablePoints(res.data.totalPoints)
    }
  }

  useEffect(() => {
    fetchPointsTransactions()
    fetchAvailablePoints()
  }, [])

  // DEMO DATA
  const DATA_categories = [
    {
      name: "New Arrivals",
    },
    {
      name: "Sale",
    },
    {
      name: "Backpacks",
    },
    {
      name: "Travel Bags",
    },
    {
      name: "Laptop Sleeves",
    },
    {
      name: "Organization",
    },
    {
      name: "Accessories",
    },
  ];

  const [showSearchForm, setShowSearchForm] = useState(false);
  const [categoriesState, setCategoriesState] = useState<string[]>([]);
  const [tableHeaders2, setTableHeader2] = useState([
    { name: "SL No", key: "sno" },
    { name: "Amount", key: "amount" },
    { name: "Used On", key: "usedDate" },
  ]);

  const renderMagnifyingGlassIcon = () => {
    return (
      <svg
        width={22}
        height={22}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11.5 21C16.7467 21 21 16.7467 21 11.5C21 6.25329 16.7467 2 11.5 2C6.25329 2 2 6.25329 2 11.5C2 16.7467 6.25329 21 11.5 21Z"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M22 22L20 20"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  };
  const renderSearchForm = () => {
    return (
      <form className="flex-1 w-full py-2 text-slate-900 dark:text-slate-100">
        <div className="bg-slate-50 dark:bg-slate-800 flex items-center space-x-1.5 px-5 h-full rounded">
          {renderMagnifyingGlassIcon()}
          <input
            type="text"
            placeholder="Search by name of the voucher"
            className="border-none bg-transparent focus:outline-none focus:ring-0 w-full text-base"
            autoFocus
          />
          {/* <button type="button" onClick={() => setShowSearchForm(false)}>
            <XMarkIcon className="w-5 h-5" />
          </button> */}
        </div>
        <input type="submit" hidden value="" />
      </form>
    );
  };

  const handleChangeCategories = (checked: boolean, name: string) => {
    checked
      ? setCategoriesState([...categoriesState, name])
      : setCategoriesState(categoriesState.filter((i) => i !== name));
  };
  const renderXClear = () => {
    return (
      <span className="flex-shrink-0 w-4 h-4 rounded-full bg-primary-500 text-white flex items-center justify-center ml-3 cursor-pointer">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-3 w-3"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fillRule="evenodd"
            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
            clipRule="evenodd"
          />
        </svg>
      </span>
    );
  };
  const renderTabsCategories = () => {
    return (
      <Popover className="relative">
        {({ open, close }) => (
          <>
            <Popover.Button
              className={`flex items-center justify-center px-4 py-2 text-sm rounded-full border focus:outline-none select-none
               ${open
                  ? "!border-primary-500 "
                  : "border-neutral-300 dark:border-neutral-700"
                }
                ${!!categoriesState.length
                  ? "!border-primary-500 bg-primary-50 text-primary-900"
                  : "border-neutral-300 dark:border-neutral-700 text-neutral-700 dark:text-neutral-300 hover:border-neutral-400 dark:hover:border-neutral-500"
                }
                `}
            >
              <svg className={`w-6 h-6`} viewBox="0 0 24 24" fill="none">
                <path
                  d="M14.3201 19.07C14.3201 19.68 13.92 20.48 13.41 20.79L12.0001 21.7C10.6901 22.51 8.87006 21.6 8.87006 19.98V14.63C8.87006 13.92 8.47006 13.01 8.06006 12.51L4.22003 8.47C3.71003 7.96 3.31006 7.06001 3.31006 6.45001V4.13C3.31006 2.92 4.22008 2.01001 5.33008 2.01001H18.67C19.78 2.01001 20.6901 2.92 20.6901 4.03V6.25C20.6901 7.06 20.1801 8.07001 19.6801 8.57001"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M16.07 16.52C17.8373 16.52 19.27 15.0873 19.27 13.32C19.27 11.5527 17.8373 10.12 16.07 10.12C14.3027 10.12 12.87 11.5527 12.87 13.32C12.87 15.0873 14.3027 16.52 16.07 16.52Z"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M19.87 17.12L18.87 16.12"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>

              <span className="ml-2">Filter</span>
              {!categoriesState.length ? (
                <ChevronDownIcon className="w-4 h-4 ml-3" />
              ) : (
                <span onClick={() => setCategoriesState([])}>
                  {renderXClear()}
                </span>
              )}
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute z-40 w-screen max-w-sm px-4 mt-3 left-0 sm:px-0 lg:max-w-md">
                <div className="overflow-hidden rounded-2xl shadow-xl bg-white dark:bg-neutral-900 border border-neutral-200 dark:border-neutral-700">
                  <div className="relative flex flex-col px-5 py-6 space-y-5">
                    <Checkbox
                      name="All Categories"
                      label="All Categories"
                      defaultChecked={categoriesState.includes(
                        "All Categories"
                      )}
                      onChange={(checked) =>
                        handleChangeCategories(checked, "All Categories")
                      }
                    />
                    <div className="w-full border-b border-neutral-200 dark:border-neutral-700" />
                    {DATA_categories.map((item) => (
                      <div key={item.name} className="">
                        <Checkbox
                          name={item.name}
                          label={item.name}
                          defaultChecked={categoriesState.includes(item.name)}
                          onChange={(checked) =>
                            handleChangeCategories(checked, item.name)
                          }
                        />
                      </div>
                    ))}
                  </div>
                  <div className="p-5 bg-neutral-50 dark:bg-neutral-900 dark:border-t dark:border-neutral-800 flex items-center justify-between">
                    <ButtonThird
                      onClick={() => {
                        close();
                        setCategoriesState([]);
                      }}
                      sizeClass="px-4 py-2 sm:px-5"
                    >
                      Clear
                    </ButtonThird>
                    <ButtonPrimary
                      onClick={close}
                      sizeClass="px-4 py-2 sm:px-5"
                    >
                      Apply
                    </ButtonPrimary>
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    );
  };

  return (
    <div className="nc-PageHome  relative overflow-hidden">
      <Helmet>
        <title>My Points</title>
      </Helmet>
      <div className="mt-12 mb-24 lg:mt-24">
        <MyPointsCard availablePoints={availablePoints} />
      </div>

      <div className="container">
        <div className="mt-6  rounded mb-24 lg:mt-4  ">
          <div className="bg-white rounded ">
            <Heading2
              className="mb-1 lg:mb-1 text-xl text-neutral-900 dark:text-neutral-50 nc-p-r-container "
              heading="Points Credited "
              subHeading=" "
            />
            <div className="mt-6  lg:ml-12 ml-0 m-6  rounded mb-24 lg:mt-4 border border-slate ">
              <div className="bg-white rounded ">
                {/* <div className=" flex items-center justify-between pb-4 m-4 pt-4">
                  <div className="flex w-full  mr-5 items-center">
                    {renderSearchForm()}
                  </div>
                </div> */}
                <DynamicTable
                  path="/hr-vouchers-details"
                  tableData={creditedPoints}
                  tableHeader={tableHeaders}
                  isCLickable={false}
                />
                {/* <div className=" pb-4 m-4 pt-4 flex flex-col mt-12 lg:mt-4 space-y-5 sm:space-y-0 sm:space-x-3 sm:flex-row sm:justify-end sm:items-center">
                  <Pagination />
                </div> */}
              </div>
            </div>
            <Heading2
              className="mb-1 lg:mb-1 text-xl text-neutral-900 dark:text-neutral-50 nc-p-r-container "
              heading="Points Debited "
              subHeading=" "
            />
            <div className="mt-6  lg:ml-12 ml-0 m-6  rounded mb-24 lg:mt-4 border border-slate ">
              <div className="bg-white rounded ">
                {/* <div className=" flex items-center justify-between pb-4 m-4 pt-4">
                  <div className="flex w-full  mr-5 items-center">
                    {renderSearchForm()}
                  </div>
                </div> */}
                <DynamicTable
                  path="/hr-vouchers-details"
                  tableData={usedPoints}
                  tableHeader={tableHeaders2}
                  isCLickable={false}
                />
                {/* <div className=" pb-4 m-4 pt-4 flex flex-col mt-12 lg:mt-4 space-y-5 sm:space-y-0 sm:space-x-3 sm:flex-row sm:justify-end sm:items-center">
                  <Pagination />
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HrDashboard;
