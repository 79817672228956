import React from 'react'
import AccordionInfo from './Accordian'
import Heading2 from 'components/Heading/Heading2'
import { Link } from 'react-router-dom'

const FAQ = () => {
  return (
    <div className='container py-12 mb-12'>
      <Heading2 heading="FAQ" className='text-center py-12' subHeading=' ' />
      <AccordionInfo/>
      <div>
      <span className="block my-12 text-center text-neutral-700 dark:text-neutral-300">
            Still Have a problem ? Contact {` `}
            <Link className="text-green-600" to="/contact">
              Help Desk{" "}
            </Link>
          </span>
      </div>
    </div>
  )
}

export default FAQ
