import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import "./styles/index.scss";
import "./index.css";
import "./fonts/line-awesome-1.3.0/css/line-awesome.css";
import "rc-slider/assets/index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from 'react-redux';
import store from '../src/redux/store'
import 'react-loading-skeleton/dist/skeleton.css'
import { PublicClientApplication } from '@azure/msal-browser';
import { initializeMsalInstance } from "./authConfig";
import { MsalProvider } from '@azure/msal-react';

const clientId = '3dcb758e-0602-4a2a-bc9f-e748c5c20e3a';
const tenendId = '96ec18ce-d387-4db1-b9e1-48ee99deb72c';
const redirectUrl = 'http://localhost:3000';

const msalInstance = initializeMsalInstance(clientId, tenendId, redirectUrl);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <MsalProvider instance={msalInstance}>
      <Provider store={store}>
        <App />
      </Provider>
    </MsalProvider>
  </React.StrictMode>
);

reportWebVitals();




// import React, { useEffect, useState } from "react";
// import ReactDOM from "react-dom/client";
// import "./styles/index.scss";
// import "./index.css";
// import "./fonts/line-awesome-1.3.0/css/line-awesome.css";
// import "rc-slider/assets/index.css";
// import App from "./App";
// import reportWebVitals from "./reportWebVitals";
// import { Provider } from 'react-redux';
// import store from '../src/redux/store';
// import 'react-loading-skeleton/dist/skeleton.css';
// import { initializeMsalInstance } from "./authConfig";
// import { MsalProvider } from '@azure/msal-react';
// import { getCompanyID } from "network/services/company";

// const AppWrapper = () => {
//   const [clientId, setClientId] = useState('');
//   const [tenendId, setTenendId] = useState('');
//   const [redirectUrl, setRedirectUrl] = useState('');

//   useEffect(() => {
//     const initCompanyDetails = async () => {
//       let obj = {
//         hostUrl:
//           String(window.location.host).includes("localhost:3000")
//             ? "puma.giftlinks.iotreeminds.com"
//             : String(window.location.host).split('/')[0],
//       };
//       const resp = await getCompanyID(obj);
//       if (resp?.data?._id) {
//         const company = resp.data;
//         console.log(company, "Company");

//         if (company.clientId) {
//           setClientId(company.clientId);
//         }
//         if (company.tenendId) {
//           setTenendId(company.tenendId);
//         }
//         if (company.redirectUrl) {
//           setRedirectUrl(company.redirectUrl);
//         }
//       }
//     };
//     initCompanyDetails();
//   }, []);
//   const msalInstance = initializeMsalInstance(clientId, tenendId, redirectUrl);
//   return (
//     <MsalProvider instance={msalInstance}>
//       <Provider store={store}>
//         <App />
//       </Provider>
//     </MsalProvider>
//   );
// };

// const root = ReactDOM.createRoot(
//   document.getElementById("root") as HTMLElement
// );

// root.render(
//   <React.StrictMode>
//     <AppWrapper />
//   </React.StrictMode>
// );

// reportWebVitals();

