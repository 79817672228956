import { axiosInstance } from "network";

export const getStates = async () => {
	try {
    const res = await axiosInstance.get("/customer/list-states");
    const data = res && res.data;
    return { data };
  } catch (err: any) {
    const errRes = (err && err.response) || "Network Error";
    return { errRes };
  }
}