import { axiosInstance } from "network";

const contactSupport = async (payload: {
	email: string,
	subject: string,
	message: string,
	companyId: string,
	fullName: string
}) => {
	try {
    const res = await axiosInstance.post("/customer/raise-support-query", payload);
    const data = res && res.data;
    return { data };
  } catch (err: any) {
    const errRes = (err && err.response) || "Network Error";
    return { errRes };
  }
}

export {
	contactSupport
}