import React, { FC, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import Pagination from "shared/Pagination/Pagination";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import SectionSliderCollections from "components/SectionSliderLargeProduct";
import SectionPromo1 from "components/SectionPromo1";
import HeaderFilterSearchPage from "components/HeaderFilterSearchPage";
import Input from "shared/Input/Input";
import ButtonCircle from "shared/Button/ButtonCircle";
import ProductCard from "components/ProductCard";
import { PRODUCTS } from "data/data";
import { getProducts } from "network/services/product";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { InfinitySpin } from "react-loader-spinner";
import { ADD_PRODUCT_ACTION } from "../redux/actions/product";
import { useDispatch } from "react-redux";


export interface PageSearchProps {
  className?: string;
}

const PageSearch: FC<PageSearchProps> = ({ className = "" }) => {
  const dispatch = useDispatch()

  const [productData, setProductData] = useState<any>();
  const [loading, setLoading] = useState(true);
  const productSelector: any = useSelector<any>((state: any) => state?.Product);
  const location = useLocation();
  const searchParams: any = new URLSearchParams(location.search);

  const getAllProduct = async () => {
    try {
      const response: any = await getProducts("");
      if (response?.data) {
        dispatch(ADD_PRODUCT_ACTION(response?.data));
      }
    } catch (error) {}
  };

  const getProduct = async () => {
    setLoading(true);
    if(productSelector && Array.isArray(productSelector) && productSelector.length == 0) {
      getAllProduct()
      return
    }
    try {
      let filteredArray = await productSelector.filter((obj: any) =>
        obj.name
          .toLowerCase()
          .includes(searchParams && searchParams?.get("name").toLowerCase())
      );
      setProductData(filteredArray);
      setLoading(false);
      console.log(filteredArray, "filteredArray");
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    getProduct();
  }, [searchParams?.get("name"), productSelector]);
  return (
    <div className={`nc-PageSearch  ${className}`} data-nc-id="PageSearch">
      <Helmet>
        <title>Search </title>
      </Helmet>
      
      <div className={`container ${searchParams?.get("name") ? "pb-24" : "py-24"}`}>
      {
        searchParams?.get("name") && <div className="py-10">
        <h1 className="pb-2">Search results for: <i><b>{searchParams?.get("name")}</b></i></h1>
        <hr />
        </div>
      }
        <div
          className={`grid gap-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 `}
        >
          {!loading &&
            productData.length >= 1 &&
            productData.map((item: any, index: number) => (
              <ProductCard data={item} key={index} />
            ))}
        </div>
        {loading && (
          <div
            style={{
              height: "60vh",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100vw%",
            }}
          >
            <InfinitySpin />
          </div>
        )}
        {!loading && productData.length <= 0 && (
          <div
            style={{
              height: "60vh",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100vw%",
            }}
          >
            <h1>No Product Found</h1>
          </div>
        )}
      </div>
    </div>
  );
};

export default PageSearch;
