import { axiosInstance } from "../../index";

const createCatalogue = async (payload: any) => {
  try {
    const res = await axiosInstance.post("customer/create-catalog", payload);
    const data = res && res.data;
    return { data };
  } catch (err: any) {
    const errRes = (err && err.response) || "Network Error";
    return { errRes };
  }
};
const addCatalogue = async (payload: any) => {
  try {
    const res = await axiosInstance.post(
      "/customer/add-product-to-catalog",
      payload
    );
    const data = res && res.data;
    return { data };
  } catch (err: any) {
    const errRes = (err && err.response) || "Network Error";
    return { errRes };
  }
};
const editCatalogueName = async (payload: any, id: string) => {
  try {
    const res = await axiosInstance.put(
      `/customer/rename-catalog/${id}`,
      payload
    );
    const data = res && res.data;
    return { data };
  } catch (err: any) {
    const errRes = (err && err.response) || "Network Error";
    return { errRes };
  }
};

const getCatalogue = async () => {
  try {
    const res = await axiosInstance.get("/customer/list-catalogs");
    const data = res && res.data;
    return { data };
  } catch (err: any) {
    const errRes = (err && err.response) || "Network Error";
    return { errRes };
  }
};
const deleteCatalogue = async (payload: any, id: string) => {
  try {
    const res = await axiosInstance.delete(
      `/customer/remove-catalog/${id}`,
      { data: payload }
    );
    const data = res && res.data;
    return { data };
  } catch (err: any) {
    const errRes = (err && err.response) || "Network Error";
    return { errRes };
  }
};

const downloadCatalog = async (id: string) => {
  try {
    const res = await axiosInstance.get(
      `/customer/download-catalog/${id}`
    );
    const data = res && res.data;

    return { data };
  } catch (err: any) {
    const errRes = (err && err.response) || "Network Error";
    return { errRes };
  }
};

const listUsersByCompanyId = async () => {
  try {
    const res = await axiosInstance.get(
      `/customer/list-all-users-by-companyId`
    );
    const data = res && res.data;

    return { data };
  } catch (err: any) {
    const errRes = (err && err.response) || "Network Error";
    return { errRes };
  }
};

const listAllPointsByUserId = async (id: string) => {
  try {
    const res = await axiosInstance.get(
      `/customer/list-all-points-by-userId/${id}`
    );
    const data = res && res.data;

    return { data };
  } catch (err: any) {
    const errRes = (err && err.response) || "Network Error";
    return { errRes };
  }
};

const hrVoucherTypeListing = async () => {
  try {
    const res = await axiosInstance.get(
      `/customer/list-vouchertypes`
    );
    const data = res && res.data;

    return { data };
  } catch (err: any) {
    const errRes = (err && err.response) || "Network Error";
    return { errRes };
  }
};


const hrListVoucherByVoucherType = async (id: string) => {
  try {
    const res = await axiosInstance.get(
      `customer/list-vouchers-by-vouchertype/${id}`
    );

    const data = res && res.data;

    return { data };
  } catch (err: any) {
    const errRes = (err && err.response) || "Network Error";
    return { errRes };
  }
};

export {
  addCatalogue,
  createCatalogue,
  getCatalogue,
  editCatalogueName,
  deleteCatalogue,
  downloadCatalog,
  listUsersByCompanyId,
  listAllPointsByUserId,
  hrVoucherTypeListing,
  hrListVoucherByVoucherType
};
