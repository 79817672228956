import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from "@azure/msal-react";
import { getLocalStorage } from "network/helper";
import { useEffect, FC } from "react"
import { useNavigate } from "react-router-dom"
import ButtonDanger from "shared/Button/ButtonDanger";
import ButtonPrimary from "shared/Button/ButtonPrimary";

interface ErrorModalProps {
    path: string;
    successMessage: string;
    redirectMessage: string;
    showModal: boolean;
    closeModal?: () => void;
}

const ErrorModal: FC<ErrorModalProps> = ({
    path,
    successMessage,
    redirectMessage,
    showModal,
    closeModal = () => { },
}) => {
    const navigate = useNavigate()

    const { instance, accounts } = useMsal();

    console.log(accounts, "accounts");

    async function handleLogout() {
        try {
            const response: any = await instance.logoutPopup();
            console.log(response);
            closeModal();
            navigate("/login");
            console.log('Logout successful');
        } catch (error) {
            console.error('Logout failed:', error);
        }
    }

    // useEffect(() => {
    //     if (showModal) {
    //         setTimeout(() => {
    //             navigate("/login")
    //         }, 5000)
    //     }
    // }, [showModal])

    return (
        <div className={showModal ? "fixed top-0 left-0 z-20 w-screen h-screen bg-white" : "hidden"}>

            <div className="flex justify-center items-center my-10 py-3">
                <h1 className="font-semibold text-primary-6000 text-xl mt-5 py-3">
                    You are trying to log in with an invalid email that is not associated with any account. Please logout and login with<br></br>
                    <p className="mt-3">the email address that is registered with your account.</p>
                </h1>
            </div>

            <div className="absolute top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2">
                <div>
                    <svg xmlns="http://www.w3.org/2000/svg" className="mx-auto" xmlnsXlink="http://www.w3.org/1999/xlink" width={100} height={100} version="1.1" id="Capa_1" viewBox="0 0 50 50" xmlSpace="preserve">
                        <circle style={{ fill: '#E74C3C' }} cx="25" cy="25" r="25" />
                        <line style={{ fill: 'none', stroke: '#FFFFFF', strokeWidth: 2, strokeLinecap: 'round', strokeLinejoin: 'round', strokeMiterlimit: 10 }} x1="15" y1="15" x2="35" y2="35" />
                        <line style={{ fill: 'none', stroke: '#FFFFFF', strokeWidth: 2, strokeLinecap: 'round', strokeLinejoin: 'round', strokeMiterlimit: 10 }} x1="35" y1="15" x2="15" y2="35" />
                    </svg>
                </div>

                <h3 className="text-center font-semibold text-primary-6000 text-2xl mt-5">{successMessage}</h3>
                {/* <p className="text-center">{redirectMessage}</p> */}
                <ButtonDanger onClick={handleLogout} className="mt-8 w-full">
                    Logout
                </ButtonDanger>
            </div>

        </div>
    )
}

export default ErrorModal;