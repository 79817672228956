import Label from "components/Label/Label";
import  { FC, useEffect, useState } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Input from "shared/Input/Input";
import CommonLayout from "./CommonLayout";
import { Helmet } from "react-helmet-async";
import { getLocalStorage, setLocalStorage } from "network/helper";
import { updateUserDetails } from "network/services/account";
import { getUserDetails } from "network/services/account";
import toast from "react-hot-toast";

export interface AccountPageProps {
  className?: string;
}

const AccountPage: FC<AccountPageProps> = ({ className = "" }) => {
  const [user, setUser] = useState<any>();

  useEffect(() => {
    setUser(getLocalStorage("user_details"));
  }, []);

  async function updateAccount() {
    const payload = {name: user.name};
    if(payload.name == "") {
      toast.error("Required fields missing");
      return 
    }
    const res = await updateUserDetails(payload)
    if (res && res.data) {
      toast.success("User Details Successfully Saved");
      setLocalStorage("user_details", user)
    } else {
      toast.error(res?.data?.message);
    }
    
  }

  return (
    <div className={`nc-AccountPage ${className}`} data-nc-id="AccountPage">
      <Helmet>
        <title>Account</title>
      </Helmet>
      <CommonLayout>
        <div className="space-y-10 sm:space-y-12">
          {/* HEADING */}
          <h2 className="text-2xl sm:text-3xl font-semibold">
            Account infomation
          </h2>
          <div className="flex flex-col md:flex-row">
            <div className="flex-shrink-0 flex items-start">
              {/* AVATAR */}
              <div className="relative rounded-full overflow-hidden flex">
                <div
                className="border  bg-neutral-100  text-primary-900"
                  style={{
                    width: "100px",
                    height: "100px",
                    textAlign: "center",
                    display:'flex',
                    alignItems:'center',
                    justifyContent:'center',
                    fontSize:'24px',
                    fontWeight:'bold',
                    borderRadius:'50%',
                  
                  }}
                >
                  {user?.name[0]}
                </div>
              </div>
            </div>
            <div className="flex-grow mt-10 md:mt-0 md:pl-16 max-w-3xl space-y-6">
              <div>
                <Label>Full name</Label>
                <Input className="mt-1.5"  value={user?.name} onChange={(e) => setUser({ ...user, name: e.target.value })} />
              </div>

              {/* ---- */}

              {/* ---- */}
              <div>
                <Label>Email</Label>
                <div className="mt-1.5 flex">
                  <span className="inline-flex items-center px-2.5 rounded-l-2xl border border-r-0 border-neutral-200 dark:border-neutral-700 bg-neutral-50 dark:bg-neutral-800 text-neutral-500 dark:text-neutral-400 text-sm">
                    <i className="text-2xl las la-envelope"></i>
                  </span>
                  <Input
                  disabled
                    className="!rounded-l-none"
                    placeholder="example@email.com"
                    defaultValue={user?.email}
                  />
                </div>
              </div>

              {/* ---- */}
              <div className="pt-2">
                <ButtonPrimary onClick={updateAccount}>Update account</ButtonPrimary>
              </div>
            </div>
          </div>
        </div>
      </CommonLayout>
    </div>
  );
};

export default AccountPage;
