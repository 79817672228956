import { FC, useState, useEffect } from "react";
import NcImage from "shared/NcImage/NcImage";
import Skeleton from "react-loading-skeleton";
import { toast } from "react-hot-toast";
import CheckIcon from 'images/check.svg';
import ButtonSecondary from "shared/Button/ButtonSecondary";
import { ArrowsPointingOutIcon } from "@heroicons/react/24/outline";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import BagIcon from "components/BagIcon";
import Button from "shared/Button/Button";

export interface VoucherCardProps {
  className?: string;
  data: any;
  isSelected: boolean;
  onClick: (entity: string | null) => void;
  showPopup: () => void;
  selectedEntity: string | null;
}

const VoucherCard: FC<VoucherCardProps> = ({
  className = "",
  data,
  showPopup,
  onClick,
  isSelected = false,
  selectedEntity = null,
}) => {
  const {
    name,
    description,
    image,
    images,
    entities,
    _id,
  } = data;

  const handleSelectItem = (entityId: string | null) => {

    if (entities.length == 1 && entityId == null) {
      onClick(entities[0]._id)
      return
    }
    if (entities.length == 0) {
      onClick(null)
      return;
    }
    if (entities.length > 1 && entityId == null) {
      toast.error("Please select a size to select the item")
      return
    }
    onClick(entityId)
  }



  return (
    <div onClick={showPopup}>
      {data ? (
        <div
          className={`nc-ProductCard relative flex flex-col bg-transparent rounded-3xl ${className} ${isSelected ? "border-2 border-[green]" : ""}`}
          data-nc-id="ProductCard"
        >

          <div className="relative flex-shrink-0 bg-slate-50 dark:bg-slate-300 rounded-3xl overflow-hidden z-1 group">
            <NcImage
              containerClassName="flex aspect-w-11 aspect-h-12 w-full h-0"
              src={Array.isArray(images) && images.length > 0 ? images[0] : image}
              className="object-cover w-full h-full drop-shadow-xl"
            />

            {
              isSelected && (
                <img src={CheckIcon} className={`absolute top-3 right-3 z-10`} />
              )
            }



          </div>

          <div className="space-y-4 px-2.5 pt-5 pb-2.5">
            {/* {renderVariants()} */}

            <div>
              <h2
                className={`overflow-hidden h-[25px] nc-ProductCard__title text-base font-semibold transition-colors`}
              >
                {name}
              </h2>
              <p
                className={`text-sm text-slate-500 dark:text-slate-400 mt-1 overflow-hidden h-[40px]`}>
                {description}
              </p>
            </div>
            <div className=" flex justify-start w-full ">
              <Button
                className="flex-1 hover:text-white text-black hover:bg-black flex-shrink-0 border border-black bg-white"
              >
                Select
              </Button>
            </div>
          </div>
        </div>
      ) : (
        <div className="md:w-[300px] w-[250px] ">
          <Skeleton className="md:h-[250px] h-[200px]  rounded-3xl " />
          <Skeleton height={"30px"} />
          <Skeleton />
          <Skeleton height={"30px"} width={"30%"} />
        </div>
      )
      }

      {/* QUICKVIEW */}
    </div >
  );
};

export default VoucherCard;
