import { getEcommOrderDetails, getEcommOrders, getPointOrderDetails, getVoucherOrderDetails } from "network/services/order";
import { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { useLocation, useNavigate, Link } from "react-router-dom";
import Prices from "components/Prices";
import { getLocalStorage } from "network/helper";
import NcImage from "shared/NcImage/NcImage";

const OrdersDetailsPage = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const [order, setOrder] = useState<any>(null)
  const [date, setDate] = useState<string>("");
  const [time, settime] = useState<string>("");

  const [status, setStatus] = useState<string>("")
  const [orderProducts, setOrderProducts] = useState<Array<any>>([])
  const [company, setCompany] = useState<string>("")
  const paymentType = location.pathname.split("/")[2].slice(0, 3)


  const getCompany = async () => {
    const comp = getLocalStorage("company")
    const name: string = comp.name
    setCompany(name.toLowerCase().slice(0, 3))
  }

  const fetchVoucherOrder = async (id: string) => {
    try {
      const res = await getVoucherOrderDetails(id);

      if (!res.data || !Array.isArray(res.data)) {
        if (res.errRes && res.errRes.data) {
          toast.error(res.errRes.data.message);
        } else {
          toast.error("Invalid Voucher Order");
        }
        navigate("/account-my-order");
        return [];
      }
      return res.data
        .filter((item: any) => item.selectedVoucherProduct != null)
        .map((item: any) => ({
          ...item.selectedVoucherProduct,
          selectedEntity: item.selectedEntity || {},
        }));
    } catch (error) {
      toast.error("An error occurred while fetching the voucher order.");
      navigate("/account-my-order");
      return [];
    }
  };


  const fetchEcommOrder = async (id: string) => {
    const res = await getEcommOrderDetails(id)
    if (!res.data) {
      toast.error(res.errRes.data.message)
      navigate("/account-my-order")
      return
    }
    return res.data
  }

  const fetchPointsOrder = async (id: string) => {
    const res = await getPointOrderDetails(id)
    if (!res.data) {
      toast.error(res.errRes.data.message)
      navigate("/account-my-order")
      return
    }
    console.log(res?.data, "resdata")

    return res.data
  }

  const fetchOrderDetails = async () => {
    if (!location.state) {
      navigate("/account-my-order")
      return
    }
    const tempOrder = location.state
    setOrder(tempOrder)
    const recentStatus = tempOrder.orderStatus.slice(-1)[0]
    const tempDate = new Date(recentStatus.datetime)
    setStatus(recentStatus.status)
    setDate(tempDate.toLocaleDateString("en-GB", {
      day: "numeric",
      month: "short",
      year: "2-digit"
    }))
    settime(formatTimeWithAmPm(tempDate))
    let products = []

    if (location.pathname.includes('voucher')) products = await fetchVoucherOrder(tempOrder._id)
    if (location.pathname.includes('ecommerce')) products = await fetchEcommOrder(tempOrder._id)
    if (location.pathname.includes('point')) products = await fetchPointsOrder(tempOrder._id)
    if (products.length > 0)
      setOrderProducts(products)
  }

  useEffect(() => {
    getCompany()
    fetchOrderDetails()
  }, [])

  const renderProductItem = (product: any, index: number) => {
    const {
      productImage: image,
      productName: prod_name,
      name,
      quantity: qty,
      entityName: size,
      pricePerPiece: price,
      pointsPerPiece: points,
      selectedEntity,
      images,
      productId
    } = product;

    console.log(product, "Onsad")

    return (
      <Link
        key={index}
        className={`flex py-4 sm:py-7 last:pb-0 first:pt-0`}
        style={{
          pointerEvents: (!!productId) ? "all" : "none"
        }}
        to={`/product-detail?id=${productId}`}
      >
        <div className="h-24 w-16 sm:w-20 flex-shrink-0 overflow-hidden rounded-xl bg-slate-100">

          {
            !image ? (
              <NcImage
                containerClassName="h-full w-full"
                src={images[0]}
                alt={name}
                className="h-full w-full object-cover object-center"
              />
            ) : (
              <NcImage
                containerClassName="h-full w-full"
                src={image}
                alt={name}
                className="h-full w-full object-cover object-center"
              />
            )
          }
        </div>
        <div className="ml-4 flex flex-1 flex-col">
          <div>
            <div className="flex justify-between ">
              <div>
                <h3 className="text-base font-medium line-clamp-1 capitalize">{prod_name ? prod_name : name}</h3>
              </div>
              {
                price && (<Prices className="mt-0.5 ml-2" price={price} />)
              }
              {
                points && (<Prices className="mt-0.5 ml-2" points={points} />)
              }
            </div>
            <p className={`text-gray-500 dark:text-slate-400 flex items-center ${selectedEntity?.name && "my-3"}`}>

              {selectedEntity?.name &&
                (
                  <>
                    <span className="hidden sm:inline-block">{selectedEntity?.name ? "Size :" : ""}</span>
                    <span className="ml-2">{selectedEntity?.name}</span>
                  </>
                )
              }

              {size && size.trim().length > 0 && (
                <>
                  <span className="hidden sm:inline-block">{size ? "Size :" : ""}</span>
                  <span className="ml-2">{size}</span>
                </>
              )}

            </p>
          </div>
          <div className="flex flex-1 items-end justify-between text-sm">
            <p className="text-gray-500 dark:text-slate-400 flex items-center">
              <span className="hidden sm:inline-block">Qty : </span>
              <span className="ml-2">{qty ? qty : 1}</span>
            </p>
          </div>
        </div>
      </Link >
    );
  };


  function formatTimeWithAmPm(date: Date): string {
    let hours: number = date.getHours();
    let minutes: number = date.getMinutes();
    const ampm: string = hours >= 12 ? 'PM' : 'AM';

    // Convert 24-hour format to 12-hour format
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    const minutesStr: string = minutes < 10 ? '0' + minutes : minutes.toString(); // add leading zero to minutes if needed

    const strTime: string = hours + ':' + minutesStr + ' ' + ampm;
    return strTime;
  }

  return (order != null) ? (
    <div className="space-y-10 sm:space-y-12 max-w-4xl mx-auto my-14 sm:my-20">
      {/* HEADING */}

      <div className="border border-slate-200 dark:border-slate-700 rounded-lg overflow-hidden z-0">
        <div className="flex flex-col sm:flex-row sm:justify-between sm:items-center p-4 sm:p-8 bg-slate-50 dark:bg-slate-500/5">
          <div>
            <p className="text-lg font-semibold">#{company}{paymentType}{order.orderNo}</p>

            <p className="text-slate-500 dark:text-slate-400 text-sm mt-1.5 sm:mt-2">
              <span>{date}</span>
              {/* <span className="ml-2">{time}</span> */}
              <span className="mx-2">·</span>
              <span className="text-primary-500 capitalize">{status}</span>
            </p>
          </div>
          {
            paymentType == "eco" && (
              <p>{order.paymentStatus == "PAYMENT_SUCCESS" ? "PAID" : "PAYMENT PENDING"}</p>
            )

          }
          {
            paymentType == "poi" && (
              <p>PAYMENT SUCCESS</p>
            )

          }

        </div>
        <div className="py-4">
          <table style={{ borderCollapse: "separate", borderSpacing: "30px 5px" }}>
            <tbody>
              <tr>
                <td>Name</td>
                <td>{order.name}</td>
              </tr>
              <tr>
                <td>Email</td>
                <td>{order.email}</td>
              </tr>
              <tr>
                <td>Contact Number</td>
                <td>{order.contactNo}</td>
              </tr>
              {
                order.altContact && order.altContact != "null" && (
                  <tr>
                    <td>Alt Number</td>
                    <td>{order.altContact}</td>
                  </tr>
                )
              }
              <tr>
                <td>Delivery Address</td>
                <td>{order.address}</td>
              </tr>
              {
                order.district && order.district != "null" && (
                  <tr>
                    <td>District</td>
                    <td>{order.district}</td>
                  </tr>
                )
              }
              <tr>
                <td>City</td>
                <td>{order.city}</td>
              </tr>
              <tr>
                <td>State</td>
                <td>{order.state}</td>
              </tr>
              <tr>
                <td>Country</td>
                <td>{order.country}</td>
              </tr>
              <tr>
                <td>Pin-Code</td>
                <td>{order.pincode}</td>
              </tr>
              <tr>
                <td><b>Order Date</b></td>
                <td className=""><b>{date}</b></td>
              </tr>
              <tr>
                <td><b>Order Time</b></td>
                <td className=""><b>{time}</b></td>
              </tr>
              {
                paymentType == "eco" && (
                  <tr>
                    <td>Total</td>
                    <td>₹ {order.total}.00</td>
                  </tr>
                )
              }
              {
                paymentType == "ecom" && (
                  <tr>
                    <td>Payment Id</td>
                    <td>{order.paymentId}</td>
                  </tr>
                )
              }
              <tr>
                <td>Delivery Link</td>
                <td>
                  {order.deliveryTrackingLink ? (
                    <a href={order.deliveryTrackingLink}>{order.deliveryTrackingLink}</a>
                  ) : "To Be Generated"
                  }
                </td>
              </tr>
              <tr>
                <td>Delivery Tracking Id</td>
                {
                  order.trackingId && (
                    <td>{order.trackingId}</td>
                  )
                }
              </tr>
            </tbody>
          </table>
        </div>
        <div className="border-t border-slate-200 dark:border-slate-700 p-2 sm:p-8 divide-y divide-y-slate-200 dark:divide-slate-700">
          {Array.isArray(orderProducts) && orderProducts.map(renderProductItem)}
        </div>
      </div>
    </div>
  ) : (
    <div>...Loading</div>
  )
};

export default OrdersDetailsPage;