import { axiosInstance } from "../../index";

const getBanners = async () => {
  try {
    const res = await axiosInstance.get("/customer/list-banners");
    const data = res && res.data;
    return { data };
  } catch (err: any) {
    const errRes = (err && err.response) || "Network Error";
    return { errRes };
  }
};
const getCompanyID = async (payload: any) => {
  try {
    const res = await axiosInstance.post(
      "/customer/get-company-by-url",
      payload
    );
    const data = res && res.data;
    return { data };
  } catch (err: any) {
    const errRes = (err && err.response) || "Network Error";
    return { errRes };
  }
};

const getFAQ = async (companyId: any) => {
  try {
    const res = await axiosInstance.get(
      `/customer/list-faqs-by-companyId/${companyId}`
    );
    const data = res && res.data;
    return { data };
  } catch (err: any) {
    const errRes = (err && err.response) || "Network Error";
    return { errRes };
  }
};
export { getBanners, getCompanyID, getFAQ };
