export const ADD_WISH_LIST = "ADD_WISH_LIST";
export const DELETE_WISH_LIST = "DELETE_WISH_LIST";
export const UPDATE_CART = "UPDATE_CART";

export const ADD_WISH_LIST_ACTION = (data: any) => {
  return {
    type: ADD_WISH_LIST,
    payload: data,
  };
};

export const DELETE_WISH_LIST_ACTION = (data: any) => {
  return {
    type: DELETE_WISH_LIST,
    payload: data,
  };
};
