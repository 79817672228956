import Label from "components/Label/Label";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Input from "shared/Input/Input";
import CommonLayout from "./CommonLayout";
import { useState } from "react";
import { changepassword } from "network/services/auth";
import toast from "react-hot-toast";

const AccountPass = () => {
  const [resetDetails, setResetDetails] = useState({
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  });
  const updatePassword = async() => {
    if (resetDetails.newPassword !== resetDetails.confirmPassword) {
      toast.error("Password does not match");
      return;
    }
    if(resetDetails.currentPassword.length === 0 || resetDetails.newPassword.length === 0 || resetDetails.confirmPassword.length === 0){
      toast.error("Please fill all the details");
      return;
    }
    if(resetDetails.newPassword !== resetDetails.confirmPassword){
      toast.error("New Password does not match");
      return;
    }
    try {
      const response = await changepassword({currentPassword: resetDetails.currentPassword, newPassword: resetDetails.newPassword});
      
      if (response?.data) {
        toast.success(response?.data?.message);
        setResetDetails({
          currentPassword: "",
          newPassword: "",
          confirmPassword: "",
        })
      } else {
        toast.error(response?.errRes?.data?.message);
      }
    } catch (error) {
      console.log(error);
      
    }
  };
  return (
    <div>
      <CommonLayout>
        <div className="space-y-10 sm:space-y-12">
          {/* HEADING */}
          <h2 className="text-2xl sm:text-3xl font-semibold">
            Update your password
          </h2>
          <div className=" max-w-xl space-y-6">
            <div>
              <Label>Current password</Label>
              <Input type="password" className="mt-1.5" placeholder="********" value={resetDetails.currentPassword} onChange={(e) => setResetDetails({ ...resetDetails, currentPassword: e.target.value })} />
            </div>
            <div>
              <Label>New password</Label>
              <Input type="password" className="mt-1.5" placeholder="********" value={resetDetails.newPassword} onChange={(e) => setResetDetails({ ...resetDetails, newPassword: e.target.value })} />
            </div>
            <div>
              <Label>Confirm password</Label>
              <Input type="password" className="mt-1.5" placeholder="********" value={resetDetails.confirmPassword} onChange={(e) => setResetDetails({ ...resetDetails, confirmPassword: e.target.value })} />
            </div>
            <div className="pt-2">
              <ButtonPrimary onClick={updatePassword}>Update password</ButtonPrimary>
            </div>
          </div>
        </div>
      </CommonLayout>
    </div>
  );
};

export default AccountPass;
