import Heading from "components/Heading/Heading";
import React, { FC } from "react";
import explore1Svg from "images/collections/explore1.svg";
import explore2Svg from "images/collections/explore2.svg";
import explore3Svg from "images/collections/explore3.svg";
//
import explore1Png from "images/collections/explore1.png";
import explore2Png from "images/collections/explore2.png";
import explore3Png from "images/collections/explore3.png";
import ShowCards from "./ShowCards";
import { getLocalStorage } from "network/helper";

interface ExploreType {
  path: string;
  id: number;
  name: string;
  desc: string;
  image: string;
  svgBg: string;
  color?: string;
}

export interface SectionGridMoreExploreProps {
  className?: string;
  gridClassName?: string;
  boxCard?: "box1" | "box4" | "box6";
  data?: ExploreType[];
}

export const DEMO_MORE_EXPLORE_DATA = [
  {
    id: 1,
    name: "Track Vouchers",
    desc: "Explore",
    image: explore1Png,
    svgBg: explore1Svg,
    color: "bg-indigo-50",
    path: "/hr-vouchers",
  },
  {
    id: 2,
    name: "Points",
    desc: "Explore",
    image: explore2Png,
    svgBg: explore2Svg,
    color: "bg-slate-100/80",
    path: "/hr-points",
  },
  {
    id: 3,
    name: "Explore Catalogs",
    desc: "Explore",
    image: explore3Png,
    svgBg: explore3Svg,
    color: "bg-violet-50",
    path: "/hr-catalog",
  },
];

const SectionGridMoreExplore: FC<SectionGridMoreExploreProps> = ({
  className = "",
  boxCard = "box4",
  gridClassName = "grid-cols-1 md:grid-cols-2 xl:grid-cols-3",
  data = DEMO_MORE_EXPLORE_DATA.filter((_, i) => i < 6),
}) => {
  const [tabActive, setTabActive] = React.useState("Man");
  const [username, setUsername] = React.useState("");

  React.useEffect(() => {
    const userDetails = getLocalStorage('user_details')
    setUsername(userDetails.name)
  }, [])

  const renderCard = (item: ExploreType) => {
    return (
      <ShowCards
        name={item.name}
        desc={item.desc}
        key={item.id}
        color={item.color}
        path={item?.path}
      />
    );
  };

  const renderHeading = () => {
    return (
      <div>
        <Heading
          className="mb-12  lg:mb-14 text-neutral-900 dark:text-neutral-50 capitalize"
          fontClass="text-3xl  md:text-4xl 2xl:text-5xl font-semibold"
          rightDescText={username}
          rightDescTextCls="text-primary-50"
        >
          Good morning
        </Heading>
        <Heading
          className="mb-12 lg:mb-14 text-neutral-900 dark:text-neutral-50"
          fontClass="text-3xl md:text-4xl 2xl:text-5xl font-semibold"
        >
          My Menu
        </Heading>
      </div>
    );
  };

  return (
    <div
      className={`nc-SectionGridMoreExplore relative ${className}`}
      data-nc-id="SectionGridMoreExplore"
    >
      {renderHeading()}
      <div className={`grid gap-4 md:gap-7 ${gridClassName}`}>
        {data.map((item) => renderCard(item))}
      </div>
    </div>
  );
};

export default SectionGridMoreExplore;
