import React, { FC } from "react";

export interface PricesProps {
  className?: string;
  price?: number;
  contentClass?: string;
  points?: number;
}

const Prices: FC<PricesProps> = ({
  className = "",
  price = -1,
  contentClass = "py-1 px-2 md:py-1.5 md:px-2.5 text-sm font-medium",
  points = -1,
}) => {
  return (
    <div className={`${className}`}>
      <div
        className={`flex items-center border-2 border-primary-6000  rounded-lg ${contentClass}`}
      >
        {
          price != -1 && (
            <span className="font-medium text-primary-6000 dark:text-primary-500 ">
              ₹ {price.toFixed(2)}
            </span>
          )
        }
        {
          points != -1 && (
            <span className="font-medium text-primary-6000 dark:text-primary-500 ">
              {points.toFixed(2)} Pts
            </span>
          )
        }
      </div>
    </div>
  );
};

export default Prices;
