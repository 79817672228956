import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { Page } from "./types";
import ScrollToTop from "./ScrollToTop";
import Footer from "shared/Footer/Footer";
import PageHome from "containers/PageHome/PageHome";
import Page404 from "containers/Page404/Page404";
import AccountPage from "containers/AccountPage/AccountPage";
import PageContact from "containers/PageContact/PageContact";
import PageAbout from "containers/PageAbout/PageAbout";
import PageSignUp from "containers/PageSignUp/PageSignUp";
import PageLogin from "containers/PageLogin/PageLogin";
import SiteHeader from "containers/SiteHeader";
import PageCollection from "containers/PageCollection";
import PageSearch from "containers/PageSearch";
import PageHome2 from "containers/PageHome/PageHome2";
import ProductDetailPage from "containers/ProductDetailPage/ProductDetailPage";
import AccountSavelists from "containers/AccountPage/AccountSavelists";
import AccountPass from "containers/AccountPage/AccountPass";
import AccountBilling from "containers/AccountPage/AccountBilling";
import AccountOrder from "containers/AccountPage/AccountOrder";
import CartPage from "containers/CartPage/CartPage";
import CheckoutPage from "containers/PageCheckout/CheckoutPage";
import ForgotPassword from "containers/ForgotPassword/Forgotpasswod";
import PasswordChangePage from "containers/PasswordChangePage/PasswordChangePage";
import VerifyAccount from "containers/VerifyAccount/VerifyAccount";
import HrDashboardPage from "containers/HrDashboard/HrDashboard";
import MyVouchersPage from "containers/MyVouchersPage/MyVouchersPage";
import HrVouchersPage from "containers/HrVoucher";
import HrVoucherDetailsPage from "containers/HrVoucher/VoucherDetails";
import MyVoucherDetailsPage from "containers/VoucherDetails/MyVoucherDetailsPage";
import MyVoucherProductDetails from "containers/MyVouchersPage/VocuherProductDetails";
import HrPointsPage from "containers/HrDashboard/HrPoints";
import HrEmployeePointsDetailsPage from "containers/HrDashboard/EmpolyeePointsDetails";
import HrCataloguePage from "containers/HrDashboard/HrCatalogue";
import FAQPAGE from "containers/Faq";

import MyPointsPage from "containers/MyPoints";

import { Toaster } from "react-hot-toast";
import Initial from "containers/Intial";
import OrdersDetailsPage from "containers/OrdersPage/OrderDetails";
import { useState, useEffect } from "react";
import { getLocalStorage } from "network/helper";
import NoInternet from "./Network/NoInternet";

export const pages: Page[] = [
  { path: "/contact", component: PageContact },
  { path: "/about", component: PageAbout },
  { path: "/", component: Initial },
  { path: "/initial", component: Initial },
  { path: "/signup", component: PageSignUp },
  { path: "/verify-account/:token", component: VerifyAccount },
  { path: "/forgot-pass", component: ForgotPassword },
  { path: "/reset-pass/:token", component: PasswordChangePage },
  { path: "/faq", component: FAQPAGE },
  { path: "/login", component: PageLogin },
  { path: "/not-found", component: Page404 },

];

export const userPages: Page[] = [
  { path: "/home", component: PageHome },
  { path: "/home2", component: PageHome2 },
  //
  { path: "/home-header-2", component: PageHome },
  { path: "/product-detail", component: ProductDetailPage },
  //
  { path: "/page-collection", component: PageCollection },
  { path: "/page-search", component: PageSearch },

  { path: "/my-points", component: MyPointsPage },
  { path: "/my-voucher-details/:id", component: MyVoucherDetailsPage },
  { path: "/my-voucher-product-details/:id", component: MyVoucherProductDetails },
  //
  { path: "/account", component: AccountPage },
  { path: "/account-savelists", component: AccountSavelists },
  { path: "/account-change-password", component: AccountPass },
  { path: "/account-addresses", component: AccountBilling },
  { path: "/account-my-order", component: AccountOrder },
  { path: "/my-order/voucher/:id", component: OrdersDetailsPage },
  { path: "/my-order/ecommerce/:id", component: OrdersDetailsPage },
  { path: "/my-order/point/:id", component: OrdersDetailsPage },

  //
  { path: "/cart", component: CartPage },
  // { path: "/checkout", component: CheckoutPage },
  { path: "/hr-dashboard", component: HrDashboardPage },
  { path: "/my-vouchers", component: MyVouchersPage },
  { path: "/hr-vouchers", component: HrVouchersPage },
  { path: "/hr-vouchers-details", component: HrVoucherDetailsPage },
  { path: "/hr-points", component: HrPointsPage },
  {
    path: "/hr-employee-points-details",
    component: HrEmployeePointsDetailsPage,
  },
  { path: "/hr-catalog", component: HrCataloguePage },
];

const MyRoutes = () => {
  const [showFooter, setShowFooter] = useState(false)

  useEffect(() => {
    const company = getLocalStorage('company')
    setShowFooter(company.isFooter && company.footer)
  }, [])

  return (
    <BrowserRouter>
      <Toaster />
      <ScrollToTop />
      <SiteHeader />
      <Routes>
        <Route path="*" element={<Navigate to="/not-found" />} />
        {pages.map(({ component: Component, path }, index) => {
          return <Route key={index} element={<Component />} path={path} />;
        })}
        {userPages.map(({ component: Component, path }, index) => {
          return <Route key={index} element={<Component />} path={path} />;
        })}
        <Route path="*" element={<Page404 />} />
      </Routes>
      {

      }
      <Footer />
    </BrowserRouter>
  );
};

export default MyRoutes;
