import { useState } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import NcModal from "shared/NcModal/NcModal";

const ActionModal = ({
  show,
  onCloseModalReportItem,
  primaryTitle,
  secondaryTitle,
  message,
  nextAction
}: any) => {
  const renderContent = () => {
    return (
      <>
        <div className="mt-4">
          <div>{message}</div>
        </div>
        <div className="mt-4 space-x-3">
          <ButtonPrimary onClick={() => nextAction()} type="submit">
            {primaryTitle}
          </ButtonPrimary>
          <ButtonSecondary
            type="button"
            onClick={() => {              onCloseModalReportItem();
            }}
          >
            {secondaryTitle}
          </ButtonSecondary>
        </div>
      </>
    );
  };

  const renderTrigger = () => {
    return null;
  };

  return (
    <NcModal
      isOpenProp={show}
      onCloseModal={onCloseModalReportItem}
      contentExtraClass="max-w-screen-md"
      renderContent={renderContent}
      renderTrigger={renderTrigger}
      modalTitle="Are you sure"
    />
  );
};

export default ActionModal;
