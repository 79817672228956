import { combineReducers, createStore } from "redux";
import CategoryReducer from "./reducers/category";
import ProductReducer from "./reducers/product";
import CartReducer from "./reducers/cart";
import ModalProductReducer from './reducers/productModalView';
import WishlistReducer from "./reducers/wishlist";
import PointsReducer from "./reducers/points";

const rootReducer = combineReducers({
  Category: CategoryReducer,
  Product: ProductReducer,
  CART: CartReducer,
  Modal_Product: ModalProductReducer,
  Wish__list: WishlistReducer,
  Points: PointsReducer,
});

const store = createStore(rootReducer);

export default store;
