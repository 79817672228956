

export const ADD_PRODUCT = "ADD_PRODUCT";



export const ADD_PRODUCT_ACTION = (data: any) => {
  return {
    type: ADD_PRODUCT,
    payload: data,
  };
};



