import { axiosInstance } from "../../index";
import axios from 'axios';

interface Cart {
  product_id: string;
  quantity: number;
  entity?: string
}

const getUserDetails = async () => {
  try {
    const res = await axiosInstance.get("/customer/get-user-details");
    const data = res && res.data;
    return { data };
  } catch (err: any) {
    const errRes = (err && err.response) || "Network Error";
    return { errRes };
  }
};

const updateUserDetails = async (payload: any) => {
  try {
    const res = await axiosInstance.post("/customer/update-user-profile", payload);
    const data = res && res.data;
    return { data };
  } catch (err: any) {
    const errRes = (err && err.response) || "Network Error";
    return { errRes };
  }
};

const verifyResetToken = async (payload: any) => {
  try {
    const res = await axios.post(process.env.REACT_APP_BASE_URL + "customer/verify-password-reset-token", payload);
    const data = res && res.data;
    return { data };
  } catch (err: any) {
    const errRes = (err && err.response) || "Network Error";
    return { errRes };
  }
};

const verifyAccount = async (subdomain: string, token: string) => {
  try {
    const res = await axios.post(process.env.REACT_APP_BASE_URL + "customer/verify-account", { subdomain }, {
      headers: {
        Authorization: token
      }
    });
    const data = res && res.data;
    return { data };
  } catch (err: any) {
    const errRes = (err && err.response) || "Network Error";
    return { errRes };
  }
}

const resetPasswordFromToken = async (payload: any) => {
  try {
    const res = await axiosInstance.post("/customer/reset-password", payload);
    const data = res && res.data;
    return { data };
  } catch (err: any) {
    const errRes = (err && err.response) || "Network Error";
    return { errRes };
  }
};

const getUserCartDetails = async () => {
  try {
    const res = await axiosInstance.get("/customer/list-cart-products");
    const data = res && res.data;
    return { data };
  } catch (err: any) {
    const errRes = (err && err.response) || "Network Error";
    return { errRes };
  }
};
const addUserCartDetails = async (payload: Cart) => {
  try {
    const res = await axiosInstance.post("/customer/add-product", payload);
    const data = res && res.data;
    return { data };
  } catch (err: any) {
    const errRes = (err && err.response) || "Network Error";
    return { errRes };
  }
};

const updateUserCartDetails = async (payload: any) => {
  try {
    const res = await axiosInstance.put("/customer/update-quantity", payload);
    const data = res && res.data;
    return { data };
  } catch (err: any) {
    const errRes = (err && err.response) || "Network Error";
    return { errRes };
  }
};

const updateUserCartEntity = async (payload: any) => {
  try {
    const res = await axiosInstance.put("/customer/update-entity", payload);
    const data = res && res.data;
    return { data };
  } catch (err: any) {
    const errRes = (err && err.response) || "Network Error";
    return { errRes };
  }
};

const deleteUserCartDetails = async (payload: any) => {
  try {
    const res = await axiosInstance.delete("/customer/remove-products", {
      data: payload,
    });
    const data = res && res.data;
    return { data };
  } catch (err: any) {
    const errRes = (err && err.response) || "Network Error";
    return { errRes };
  }
};

const getUserWishListDetails = async () => {
  try {
    const res = await axiosInstance.get("/customer/list-items");
    const data = res && res.data;
    return { data };
  } catch (err: any) {
    const errRes = (err && err.response) || "Network Error";
    return { errRes };
  }
};
const addUserWishListDetails = async (payload: any) => {
  try {
    const res = await axiosInstance.post("/customer/add-item", payload);
    const data = res && res.data;
    return { data };
  } catch (err: any) {
    const errRes = (err && err.response) || "Network Error";
    return { errRes };
  }
};

const deleteUserWishListDetails = async (id: any) => {
  try {
    const res = await axiosInstance.delete(`/customer/remove-item/${id}`);
    const data = res && res.data;
    return { data };
  } catch (err: any) {
    const errRes = (err && err.response) || "Network Error";
    return { errRes };
  }
};

// Adresses

const getUserAddresses = async () => {
  try {
    const res = await axiosInstance.get("/customer/list-addresses");
    const data = res && res.data;
    return { data };
  } catch (err: any) {
    const errRes = (err && err.response) || "Network Error";
    return { errRes };
  }
};

const addUserAddress = async (payload: any) => {
  try {
    const res = await axiosInstance.post("/customer/add-address", payload);
    const data = res && res.data;
    return { data };
  } catch (err: any) {
    const errRes = (err && err.response) || "Network Error";
    return { errRes };
  }
};

const updateUserAddress = async (id: any, payload: any) => {
  try {
    const res = await axiosInstance.put(`/customer/update-address/${id}`, payload);
    const data = res && res.data;
    return { data };
  } catch (err: any) {
    const errRes = (err && err.response) || "Network Error";
    return { errRes };
  }
};

const deleteUserAddress = async (id: any) => {
  try {
    const res = await axiosInstance.delete(`/customer/remove-address/${id}`);
    const data = res && res.data;
    return { data };
  } catch (err: any) {
    const errRes = (err && err.response) || "Network Error";
    return { errRes };
  }
};

const getUserVouchers = async () => {
  try {
    const res = await axiosInstance.get('/customer/list-my-vouchers')
    const data = res && res.data;
    return { data };
  } catch (err: any) {
    const errRes = (err && err.response) || "Network Error";
    return { errRes };
  }
}

const getVoucherProducts = async (voucherId: string) => {
  try {
    const res = await axiosInstance.get(`/customer/list-voucher-products/${voucherId}`)
    const data = res && res.data;
    return { data };
  } catch (err: any) {
    const errRes = (err && err.response) || "Network Error";
    return { errRes };
  }
}

const getAvailablePoints = async () => {
  try {
    const res = await axiosInstance.get(`/customer/get-my-active-points`)
    const data = res && res.data;
    return { data };
  } catch (err: any) {
    const errRes = (err && err.response) || "Network Error";
    return { errRes };
  }
}

const getPointsCredited = async () => {
  try {
    const res = await axiosInstance.get(`/customer/get-my-points-credited`)
    const data = res && res.data;
    return { data };
  } catch (err: any) {
    const errRes = (err && err.response) || "Network Error";
    return { errRes };
  }
}

const getPointsUsage = async () => {
  try {
    const res = await axiosInstance.get(`/customer/get-my-points-usage`)
    const data = res && res.data;
    return { data };
  } catch (err: any) {
    const errRes = (err && err.response) || "Network Error";
    return { errRes };
  }
}

export {
  getUserDetails,
  updateUserDetails,
  getUserCartDetails,
  addUserCartDetails,
  deleteUserCartDetails,
  updateUserCartDetails,
  deleteUserWishListDetails,
  addUserWishListDetails,
  getUserWishListDetails,
  getUserAddresses,
  addUserAddress,
  updateUserAddress,
  deleteUserAddress,
  verifyResetToken,
  resetPasswordFromToken,
  verifyAccount,
  getUserVouchers,
  getVoucherProducts,
  getAvailablePoints,
  getPointsCredited,
  getPointsUsage,
};
