import React, { useEffect, useState } from "react";
import { authenticate, isAuth, logout, setLocalStorage } from "network/helper";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { getCompanyID } from "../../network/services/company";
import { InfinitySpin } from "react-loader-spinner";
import { setCompanyStyles } from "utils/setCompanyStyles";
const Initial = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const setUpInitialData = async () => {
    setLoading(true);
    let obj = {
      hostUrl:
        window.location.host === "localhost:3000"
          ? process.env.REACT_APP_DEFAULT_COMPANY_URL
          : window.location.host,
    };

    try {
      const response = await getCompanyID(obj);
      if (response?.data?._id) {
        setLocalStorage("company", response?.data);
        setCompanyStyles(document, response?.data)
        setLoading(false);
        navigate("/login");
      } else {
        setLoading(false);
        navigate("/not-found");
      }
    } catch {
      setLoading(false);
      toast.error("Something went wrong");
    }
  };

  useEffect(() => {
    if (isAuth()) {
      navigate("/home");
    } else {
      setUpInitialData();
    }
  }, []);

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "90vh",
      }}
    >
      {loading && <InfinitySpin />}
    </div>
  );
};

export default Initial;
