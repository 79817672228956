import React, { FC, useEffect, useState } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import LikeButton from "components/LikeButton";
import { StarIcon } from "@heroicons/react/24/solid";
import BagIcon from "components/BagIcon";
import NcInputNumber from "components/NcInputNumber";
import { PRODUCTS } from "data/data";
import {
  NoSymbolIcon,
  ClockIcon,
  SparklesIcon,
  ArrowsPointingOutIcon,
} from "@heroicons/react/24/outline";
import IconDiscount from "components/IconDiscount";
import Prices from "components/Prices";
import toast from "react-hot-toast";
import detail1JPG from "images/products/detail1.jpg";
import detail2JPG from "images/products/detail2.jpg";
import detail3JPG from "images/products/detail3.jpg";
import NotifyAddTocart from "./NotifyAddTocart";
import AccordionInfo from "containers/ProductDetailPage/AccordionInfo";
import { Link } from "react-router-dom";
import { isAuth } from "network/helper";
import LoginNotify from "./LoginNotify";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import AddToCatalogueModal from "./AddToCatalogueModal";
import { Transition } from "@headlessui/react";
import { ADD_MODAL_VIEW_PRODUCT_ACTION } from "../redux/actions/productModalView";
import { useDispatch, useSelector } from "react-redux";
import {
  addUserCartDetails,
  updateUserCartDetails,
  deleteUserCartDetails,
} from "network/services/account";
import {
  ADD_CART_ACTION,
  DELETE_CART_ACTION,
  UPDATE_CART_ACTION,
} from "../redux/actions/cart";
import { useLocation } from "react-router-dom";
import CatalogueCreateModal from "./CatalogueModal";
export interface ProductQuickViewProps {
  className?: string;
  close?: () => void;
}

const ProductQuickView: FC<ProductQuickViewProps> = ({ className = "", close = () => { } }) => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const CartSelector = useSelector((state: any) => state.CART);
  const { sizes, variants, status, allOfSizes } = PRODUCTS[0];
  const LIST_IMAGES_DEMO = [detail1JPG, detail2JPG, detail3JPG];
  const dispatch = useDispatch();
  const cartSelector: any = useSelector((state: any) => state?.CART);
  const [variantActive, setVariantActive] = React.useState(0);
  const [sizeSelected, setSizeSelected] = React.useState("");
  const [qualitySelected, setQualitySelected] = React.useState(1);
  const [showModal, setShowModal] = React.useState(false);
  const [openAddToCatalogueModal, setOpenAddToCatalogueModal] = React.useState(false);
  const ProductSelector: any = useSelector((state: any) => state?.Modal_Product);
  const [isAlreadyInCart, setIsAlreadyInCart] = useState(false);

  useEffect(() => {
    if (openAddToCatalogueModal) {
      setShowModal(false);
    }
  }, [openAddToCatalogueModal]);

  const handleQtyChange = (value: any) => {
    setQualitySelected(value)
    const cartItem = CartSelector.find(
      (cartItem: any) => cartItem.product?._id === ProductSelector._id
    );
    if (isAlreadyInCart) updateCart(cartItem, value);
  };

  const updateCart = async (item: any, qty: number) => {
    let obj = {
      cartId: item._id,
      quantity: +qty,
    };
    try {
      const response = await updateUserCartDetails(obj);
      if (response?.data) {
        dispatch(UPDATE_CART_ACTION(obj));

      }
    } catch (error) { }
  };

  useEffect(() => {
    let cartItem = CartSelector.filter(
      (cartItem: any) => cartItem.product?._id === ProductSelector._id
    );

    if (cartItem.length > 1) {
      cartItem = cartItem.find((item: any) => {
        return item.entity._id == sizeSelected
      })
    } else cartItem = cartItem[0]

    let inCart = false;
    let quantity = 1;

    if (cartItem) {
      inCart = true;
      quantity = cartItem.quantity

      if (cartItem.entity && sizeSelected != cartItem.entity._id) {
        inCart = false;
        quantity = 1;
      }
    }

    setIsAlreadyInCart(inCart)
    setQualitySelected(quantity)

    if (!sizeSelected && ProductSelector && ProductSelector.entitiesList && ProductSelector.entitiesList.length > 0) {
      setSizeSelected(ProductSelector.entitiesList[0]._id)
    }

  }, [CartSelector.length, sizeSelected]);

  const notifyAddToCart = async (data: any) => {
    let obj = {
      product: data,
      quantity: qualitySelected,
      entity: data.entitiesList.find((entity: any) => entity._id == sizeSelected),
    };

    if (isAlreadyInCart) {
      toast.custom(
        (t) => (
          <Transition
            appear
            show={t.visible}
            className="p-4 max-w-md w-full bg-white dark:bg-slate-800 shadow-lg rounded-2xl pointer-events-auto ring-1 ring-black/5 dark:ring-white/10 text-slate-900 dark:text-slate-200"
            enter="transition-all duration-150"
            enterFrom="opacity-0 translate-x-20"
            enterTo="opacity-100 translate-x-0"
            leave="transition-all duration-150"
            leaveFrom="opacity-100 translate-x-0"
            leaveTo="opacity-0 translate-x-20"
          >
            <p className="block text-base font-semibold leading-none">
              Item Exist
            </p>
          </Transition>
        ),
        { position: "top-right", id: "nc-product-notify", duration: 1000 }
      );
    } else {
      let obj2: {
        product_id: any,
        quantity: any,
        entity?: any
      } = {
        product_id: data?._id,
        quantity: qualitySelected,
      };
      if (sizeSelected) obj2.entity = sizeSelected
      try {
        const resp = await addUserCartDetails(obj2);

        if (resp?.data) {
          dispatch(ADD_CART_ACTION([{ ...obj, _id: resp.data._id }]));
          close();
          toast.custom(
            (t) => (
              <Transition
                appear
                show={t.visible}
                className="p-4 max-w-md w-full bg-white dark:bg-slate-800 shadow-lg rounded-2xl pointer-events-auto ring-1 ring-black/5 dark:ring-white/10 text-slate-900 dark:text-slate-200"
                enter="transition-all duration-150"
                enterFrom="opacity-0 translate-x-20"
                enterTo="opacity-100 translate-x-0"
                leave="transition-all duration-150"
                leaveFrom="opacity-100 translate-x-0"
                leaveTo="opacity-0 translate-x-20"
              >
                <p className="block text-base font-semibold leading-none">
                  Added to cart!
                </p>
                <div className="border-t border-slate-200 dark:border-slate-700 my-4" />
                {renderProductCartOnNotify(data)}
              </Transition>
            ),
            { position: "top-right", id: "nc-product-notify", duration: 2000 }
          );
        }
      } catch (error) { }
    }
  };

  const renderProductCartOnNotify = (data: any) => {
    return (
      <div className="flex ">
        <div className="ml-4 flex flex-1 flex-col">
          <div>
            <div className="flex justify-between ">
              <div className="h-24 w-24 flex-shrink-0 overflow-hidden rounded-xl bg-slate-100">
                <img
                  src={data?.images[data.defaultImage - 1]}
                  className="h-full w-full object-cover"
                />
              </div>
              <div className="w-[50%]">
                <h3 className="text-base font-medium ">{data?.name}</h3>
                {
                  data && data.entitiesList && Array.isArray(data.entitiesList) && data.entitiesList.length > 0 && (
                    <p className="mt-1 text-sm text-slate-500 dark:text-slate-400 flex flex-row">
                      <span>Size: {" "}</span>
                      {data.entitiesList.map((item: any) => {
                        return (
                          <div key={item._id}>
                            {item._id === sizeSelected ? (
                              <p>:{"  "} {item.name}</p>
                            ) : null}
                          </div>
                        );
                      }) || "S"}
                    </p>
                  )
                }
                <span>Qty: {qualitySelected}</span>
              </div>
              <Prices price={data?.currentPrice} className="mt-0.5" />
            </div>
          </div>
          <div className="flex flex-1 pt-3 items-end justify-between text-sm">
            <div className="flex">
              <Link
                to={"/cart"}
                className="font-medium text-primary-6000 dark:text-primary-500 "
              >
                View cart
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  };



  const renderSectionContent = () => {
    return (
      <div className="space-y-8">
        {/* ---------- 1 HEADING ----------  */}

        <div>
          <h2 className="text-2xl font-semibold hover:text-primary-6000 transition-colors">
            <Link to="/product-detail">{ProductSelector?.name}</Link>
          </h2>

          <div className="flex items-center mt-5 space-x-4 sm:space-x-5">
            {/* <div className="flex text-xl font-semibold">$112.00</div> */}
            <Prices
              contentClass="py-1 px-2 md:py-1.5 md:px-3 text-lg font-semibold"
              price={ProductSelector?.currentPrice}
            />

            <div className="h-6 border-l border-slate-300 dark:border-slate-700"></div>
          </div>
        </div>
        <div className="">{renderSizeList()}</div>


        {/*  ---------- 4  QTY AND ADD TO CART BUTTON */}
        <div className="flex space-x-3.5 mr-2">
          <div className="flex items-center justify-center bg-slate-100/70 dark:bg-slate-800/70 px-2 py-3 sm:p-3.5 rounded-full">
            <NcInputNumber
              defaultValue={qualitySelected}
              onChange={(value: any) => handleQtyChange(value)}
            />
          </div>

          {isAlreadyInCart ? (
            <ButtonSecondary className=" border border-slate flex-1 flex-shrink-0">
              {/* <BagIcon className="hidden sm:inline-block w-5 h-5 mb-0.5" /> */}
              <span className="ml-3">Added To cart</span>
            </ButtonSecondary>
          ) : (
            <ButtonPrimary
              className="flex-1 flex-shrink-0"
              onClick={() => notifyAddToCart(ProductSelector)}
            >
              <BagIcon className="hidden sm:inline-block w-5 h-5 mb-0.5" />
              <span className="ml-3">Add to cart</span>
            </ButtonPrimary>
          )}
        </div>
        <div className="flex space-x-3 mr-2">
          {isAuth()?.isHR && (
            <ButtonSecondary
              onClick={() => setShowModal(true)}
              className=" border border-slate flex-1 flex-shrink-0"
            >
              <BagIcon className="hidden sm:inline-block w-5 h-5 mb-0.5" />
              <span className="ml-3">Add to Catalog</span>
            </ButtonSecondary>
          )}
          {/* <ButtonSecondary
            onClick={() => setShowModal(true)}
            className=" border border-slate flex-1 flex-shrink-0"
          >
            <BagIcon className="hidden sm:inline-block w-5 h-5 mb-0.5" />
            <span className="ml-3">Add to Catalog</span>
          </ButtonSecondary> */}
        </div>

        {/*  */}
        <hr className=" border-slate-200 dark:border-slate-700"></hr>
        {/*  */}

        {/* ---------- 5 ----------  */}
        <AccordionInfo
          data={[
            {
              name: "Description",
              content: `${ProductSelector?.description}`,
            },
            {
              name: "Features",
              content: ` ${ProductSelector?.features
                .map((item: any) => {
                  return `<li>${item}</li>`;
                })
                .join("")}`,
            },
          ]}
        />

        {/* ---------- 6 ----------  */}
      </div>
    );
  };

  const renderSizeList = () => {
    if (!ProductSelector?.entitiesList) {
      return null;
    }
    return (
      <div>
        <div className="flex justify-between font-medium text-sm ">
          <label htmlFor="">
            {
              ProductSelector && ProductSelector.entitiesList && Array.isArray(ProductSelector.entitiesList) && ProductSelector.entitiesList.length > 0 && (
                <span className="">
                  Size:{" "}
                  {ProductSelector?.entitiesList.map((item: any) => {
                    return (
                      <span key={item._id} className="">
                        {item._id === sizeSelected ? (
                          <span className="uppercase">{item.name}</span>
                        ) : null}
                      </span>
                    );
                  })}
                </span>
              )}
          </label>
        </div>
        <div className="flex flex-row gap-2 mt-3 grid grid-cols-5">
          {ProductSelector.entitiesList.map((size: any, index: number) => {
            const isActive = size._id === sizeSelected;
            return (
              <div
                key={index}
                className={`relative min-w-16 text-center rounded-2xl border py-2 px-2 
                text-sm sm:text-base uppercase font-semibold select-none overflow-hidden z-0 ${isActive
                    ? "bg-primary-6000 border-primary-6000 text-white hover:bg-primary-6000"
                    : "border-slate-300 dark:border-slate-600 text-slate-900 dark:text-slate-200 hover:bg-neutral-50 dark:hover:bg-neutral-700"
                  }`}
                onClick={() => {
                  setSizeSelected(size?._id);
                }}
              >
                {size?.name}
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  return (
    <div className={`nc-ProductQuickView ${className}`}>
      {/* MAIn */}
      <div className="lg:flex">
        {/* CONTENT */}
        <div className="w-full lg:w-[50%] ">
          {/* HEADING */}
          <div className="relative">
            <div className="aspect-w-16 aspect-h-16">
              <img
                src={ProductSelector?.images && ProductSelector?.images[ProductSelector.defaultImage - 1]}
                className="w-full rounded-xl object-cover"
                alt="product detail 1"
              />
            </div>
          </div>
          <div className="hidden lg:grid grid-cols-2 gap-3 mt-3 sm:gap-6 sm:mt-6 xl:gap-5 xl:mt-5">
            {ProductSelector?.images &&
              ProductSelector?.images
                .slice(1, 3)
                .map((item: string, index: number) => {
                  return (
                    <div key={index} className="aspect-w-3 aspect-h-4">
                      <img
                        src={item}
                        className="w-full rounded-xl object-cover"
                        alt={`product detail ${index + 1}`}
                      />
                    </div>
                  );
                })}
          </div>
        </div>

        {/* SIDEBAR */}
        <div className="w-full lg:w-[50%] pt-6 lg:pt-0 lg:pl-7 xl:pl-8">
          {renderSectionContent()}
        </div>
      </div>
      <AddToCatalogueModal
        product_id={ProductSelector._id}
        show={showModal}
        onCloseModalReportItem={() => setShowModal(false)}
        onclick={() => setOpenAddToCatalogueModal(true)}
      />
      <CatalogueCreateModal
        show={openAddToCatalogueModal}
        onCloseModalReportItem={() => setOpenAddToCatalogueModal(false)}
      />
    </div>
  );
};

export default ProductQuickView;
