const ModalProductReducer = (
  state = {},
  action: { type: any; payload: any }
) => {
  switch (action.type) {
    case "ADD_MODAL_PRODUCT":
      return { ...state, ...action?.payload };
    case "DELETE_MODAL_PRODUCT":
      return {};
    default:
      return state;
  }
};

export default ModalProductReducer;
