import axios from "axios";
import Cookies from "js-cookie";
import { logout } from "./helper";

const baseURL = (process.env.REACT_APP_ENVIRONMENT === "prod") ? process.env.REACT_APP_BASE_URL :
  (process.env.REACT_APP_ENVIRONMENT === "stage") ? process.env.REACT_APP_STAGING_BASE_URL :
    process.env.REACT_APP_LOCAL_BASE_URL

const axiosInstance = axios.create({
  baseURL,
  timeout: 10 * 60 * 1000,
  timeoutErrorMessage:
    "We are unable to connect to the server. Please try again later.",
});

const getTokenFromCookies = () => {
  return Cookies.get("token"); // Replace 'token' with the actual cookie name
};

axiosInstance.interceptors.request.use(async (config) => {
  const token = getTokenFromCookies();

  if (token) {
    config.headers["Authorization"] = `${token}`;
  }

  config.headers["Content-Type"] = "application/json";
  return config;
});

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;

    // Check if response status is 400 and message is "Token has expired"
    if (
      error.response.status === 400 &&
      error.response.data.message === "Token has expired"
    ) {
      // Redirect to login page
      logout();
      window.location.href = "/initial";
      return Promise.reject(error);
    }

    return Promise.reject(error);
  }
);

export { axiosInstance };
