
function hexToRgb(hex:any) {
  hex = hex.replace(/^#/, '');
  var bigint = parseInt(hex, 16);
  var r = (bigint >> 16) & 255;
  var g = (bigint >> 8) & 255;
  var b = bigint & 255;

  return  `rgb(${r},${g},${b})`;
}

const companyDetails = window.localStorage.getItem('company')
const formattedCompanyDetails = JSON.parse(String(companyDetails))

const Primary_color = {
  _50: formattedCompanyDetails?.primary50,
  _100: formattedCompanyDetails?.primary100,
  _200: formattedCompanyDetails?.primary200,
  _300: formattedCompanyDetails?.primary300,
  _400: formattedCompanyDetails?.primary400,
  _500: formattedCompanyDetails?.primary500,
  _600: formattedCompanyDetails?.primary600,
  _700: formattedCompanyDetails?.primary700,
  _800: formattedCompanyDetails?.primary800,
  _900: formattedCompanyDetails?.primary900,
};
const Secondary_color = {
  _50: formattedCompanyDetails?.secondary50,
  _100: formattedCompanyDetails?.secondary100,
  _200: formattedCompanyDetails?.secondary200,
  _300: formattedCompanyDetails?.secondary300,
  _400: formattedCompanyDetails?.secondary400,
  _500: formattedCompanyDetails?.secondary500,
  _600: formattedCompanyDetails?.secondary600,
  _700: formattedCompanyDetails?.secondary700,
  _800: formattedCompanyDetails?.secondary800,
  _900: formattedCompanyDetails?.secondary900,
};
const Neutral_color = {
  _50: formattedCompanyDetails?.neutral50,
  _100: formattedCompanyDetails?.neutral100,
  _200: formattedCompanyDetails?.neutral200,
  _300: formattedCompanyDetails?.neutral300,
  _400: formattedCompanyDetails?.neutral400,
  _500: formattedCompanyDetails?.neutral500,
  _600: formattedCompanyDetails?.neutral600,
  _700: formattedCompanyDetails?.neutral700,
  _800: formattedCompanyDetails?.neutral800,
  _900: formattedCompanyDetails?.neutral900,
};

export { Primary_color, Secondary_color, Neutral_color,hexToRgb };
