import { FC } from 'react';
import toast from 'react-hot-toast';
import { Link } from 'react-router-dom';
import ButtonPrimary from 'shared/Button/ButtonPrimary';
import ButtonSecondary from 'shared/Button/ButtonSecondary';

interface OrderListProps {
  list: Array<any>;
  handleCancelOrder: (id: string) => void;
  company: string;
}

function formatTimeWithAmPm(date: Date): string {
  let hours: number = date.getHours();
  let minutes: number = date.getMinutes();
  const ampm: string = hours >= 12 ? 'PM' : 'AM';

  // Convert 24-hour format to 12-hour format
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  const minutesStr: string = minutes < 10 ? '0' + minutes : minutes.toString(); // add leading zero to minutes if needed

  const strTime: string = hours + ':' + minutesStr + ' ' + ampm;
  return strTime;
}

const OrderList: FC<OrderListProps> = ({
  list = [],
  handleCancelOrder,
  company,
}) => {
  return (
    <div>
      {
        list.map((order: any, index: number) => {
          const recentStatus = order.orderStatus.slice(-1)[0]
          const tempDate = new Date(recentStatus.datetime);
          console.log(tempDate, "tempDate")
          const date = tempDate.toLocaleDateString("en-GB", {
            day: "numeric",
            month: "short",
            year: "2-digit"
          })
          const timeWithAmPm = formatTimeWithAmPm(tempDate);
          const type = order.orderType == "ECOMM" ? "ecommerce" : order.orderType == "POINTS" ? "point" : "voucher"
          return (
            <div className="border border-slate-200 dark:border-slate-700 rounded-lg overflow-hidden z-0">
              <div className="flex flex-col sm:flex-row sm:justify-between sm:items-center p-4 sm:p-8 bg-slate-50 dark:bg-slate-500/5">
                <div>
                  <p className="text-lg font-semibold">#{company}{type.slice(0, 3)}{order.orderNo}</p>
                  <p className="text-slate-500 dark:text-slate-400 text-sm mt-1.5 sm:mt-2">
                    <span>{date}</span><br></br>
                    <span className='my-2'>{timeWithAmPm}</span>
                    <span className="mx-2">·</span>
                    <span className="text-primary-500">{recentStatus.status}</span>
                  </p>
                </div>
                <div className="flex align-items-center justify-between px-2">
                  <div className="mt-3 mx-2 sm:mt-0">
                    <ButtonSecondary
                      sizeClass="py-2.5 px-4 sm:px-6"
                      fontSize="text-sm font-medium"
                      onClick={() => {
                        if (type != "ecommerce") return toast.error("This type of order cannot be cancelled");
                        if (recentStatus.status.toLowerCase() == "cancelled") return toast.error("Order already cancelled");
                        handleCancelOrder(order._id)
                      }}
                      // disabled={type!="ecommerce" || recentStatus.status.toLowerCase() == "cancelled"}
                      className={`${type != "ecommerce" ? "bg-[#f5f5f5] text-[#aaa]" : ""}`}
                    >
                      Cancel
                    </ButtonSecondary>
                  </div>
                  <Link
                    to={`/my-order/${type.toLowerCase()}/${order._id}`}
                    state={order}
                    className="mt-3 mx-2 sm:mt-0"
                  >
                    <ButtonPrimary
                      sizeClass="py-2.5 px-4 sm:px-6"
                      fontSize="text-sm font-medium"
                    >
                      View
                    </ButtonPrimary>
                  </Link>
                </div>
              </div>
            </div>
          )
        })
      }
    </div>
  )
}

export default OrderList;