import { Helmet } from "react-helmet-async";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import DynamicTable from "components/CustomTable";
import Pagination from "shared/Pagination/Pagination";
import { Popover, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/24/solid";
import { useState, Fragment, useEffect } from "react";
import ButtonThird from "shared/Button/ButtonThird";
import Checkbox from "shared/Checkbox/Checkbox";
import { Link } from "react-router-dom";
import { listUsersByCompanyId } from "network/services/HR";
import { toast } from "react-hot-toast";

const HrDashboard = () => {

  const [searchQuery, setSearchQuery] = useState('');

  // Function to handle form submission
  const handleSubmit = (e:any) => {
    e.preventDefault();
    // Perform filtering or searching based on searchQuery
    // For example:
    // filterData(searchQuery);
    console.log("Searching for:", searchQuery);
  };

  // Function to handle input change
  const handleInputChange = (e:any) => {
    setSearchQuery(e.target.value);
    filterData(e.target.value);
  };

  const filterData = (query: string) => {
    const filtered = tableData.filter(item => {
      // Example filter condition, you may customize according to your needs
      return (item.name.toLowerCase().includes(query.toLowerCase())||item.email.toLowerCase().includes(query.toLowerCase()));
    });
    setFilteredTableData(filtered);
  };

  const [tableHeaders, setTableHeader] = useState([
    { name: "Slno", key: "slno" },
    { name: "Name", key: "name" },
    { name: "Employee Email", key: "email" },
    { name: "Enabled?", key: "isEnabled" },
    { name: "Verified?", key: "isVerified" },
    // { name: "HR Access", key: "isHR" },
  ]);

  interface TableItem {
    slno: number;
    id: string;
    name: string;
    email: string;
    isEnabled: string;
    isVerified: string;
    isHR: string;
    isDeleted: string;
  }
  
  const [tableData, setTableData] = useState<TableItem[]>([]);
  const [filteredTableData, setFilteredTableData] = useState<TableItem[]>([]);
  
  const getUsersByCompanyId = async () => {
    try {
      const resp = await listUsersByCompanyId();
      if (resp?.data) {
        let count = 1; // Initialize count variable
        // Assuming resp.data is an array of objects with properties matching TableItem interface
        const userData: TableItem[] = resp.data.map((user: any) => ({
          slno: count++,
          id: user._id,
          name: user.name,
          email: user.email,
          isEnabled: user.isEnabled===true?"yes":"no",
          isVerified: user.isVerified===true?"yes":"no",
          isHR: user.isHR===true?"yes":"no",
          isDeleted: user.isDeleted===true?"yes":"no",
        }));
        setTableData(userData);
        setFilteredTableData(userData);
      } else {
        toast.error(resp?.data.message);
      }
    } catch (error) {
      // Handle error
      console.log("Handled the error in try catch block")
      console.error(error);
    }
  };  


  useEffect(()=>{
    const response = getUsersByCompanyId();
  },[])

  const renderMagnifyingGlassIcon = () => {
    return (
      <svg
        width={22}
        height={22}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11.5 21C16.7467 21 21 16.7467 21 11.5C21 6.25329 16.7467 2 11.5 2C6.25329 2 2 6.25329 2 11.5C2 16.7467 6.25329 21 11.5 21Z"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M22 22L20 20"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  };
  const renderSearchForm = () => {
    return (
      <div className="flex-1 w-full py-2 text-slate-900 dark:text-slate-100">
        <div className="bg-slate-50 dark:bg-slate-800 flex items-center space-x-1.5 px-5 h-full rounded">
          {renderMagnifyingGlassIcon()}
          <input
            type="text"
            placeholder="Search by name or email of the employee"
            className="border-none bg-transparent focus:outline-none focus:ring-0 w-full text-base"
            autoFocus
            value={searchQuery}
            onChange={handleInputChange}
          />
          {/* <button type="button" onClick={() => setShowSearchForm(false)}>
            <XMarkIcon className="w-5 h-5" />
          </button> */}
        </div>
      </div>
    );
  };

 
  const renderXClear = () => {
    return (
      <span className="flex-shrink-0 w-4 h-4 rounded-full bg-primary-500 text-white flex items-center justify-center ml-3 cursor-pointer">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-3 w-3"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fillRule="evenodd"
            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
            clipRule="evenodd"
          />
        </svg>
      </span>
    );
  };


  return (
    <div className="nc-PageHome  relative overflow-hidden">
      <Helmet>
        <title>Hr Points</title>
      </Helmet>

      <div className="container">

      <div className="mb-12 sm:mb-16">
        <div className="block mt-3 sm:mt-5 text-xs sm:text-sm font-medium text-slate-700 dark:text-slate-400">
          <Link to={"/hr-dashboard"} className="">
            My Menu
          </Link>
          <span className="text-xs mx-1 sm:mx-1.5">/</span>
          <span className="underline">Points Credited</span>
        </div>
      </div>
        <div className="mt-6  rounded mb-24 lg:mt-4 border border-slate ">
          <div className="bg-white rounded ">
            <div className=" flex items-center justify-between pb-4 m-4 pt-4">
              <div className="flex w-full  mr-5 items-center">
                {renderSearchForm()}
              </div>
            </div>
            <DynamicTable path={'/hr-employee-points-details'} tableData={filteredTableData} tableHeader={tableHeaders} />
            <div className=" pb-4 m-4 pt-4 flex flex-col mt-12 lg:mt-4 space-y-5 sm:space-y-0 sm:space-x-3 sm:flex-row sm:justify-end sm:items-center">
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HrDashboard;
