import { Popover, Transition } from "@headlessui/react";
import Prices from "components/Prices";
import { Product, PRODUCTS } from "data/data";
import { Fragment } from "react";
import { Link } from "react-router-dom";
import { Primary_color } from "styles/theme_color";

export default function WalletIcon() {
  return (
    <Popover className="relative">
      {({ open, close }) => (
        <>
          {/* <Popover.Button
            className={`
                ${open ? "" : "text-opacity-90"}
                 group w-10 h-10 sm:w-12 sm:h-12 hover:bg-slate-100 dark:hover:bg-slate-800 rounded-full inline-flex items-center justify-center focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 relative`}
          > */}
            {/* <div className="w-3.5 h-3.5 flex items-center justify-center bg-primary-500 absolute top-1.5 right-1.5 rounded-full text-[10px] leading-none text-white font-medium">
                            <span className="mt-[1px]">99</span>
                        </div> */}
            <div>
            <svg
              id="Layer_1"
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              width="24px"
              height="24px"
              viewBox="0 0 2.4 2.4"
              enableBackground="new 0 0 64 64"
              xmlSpace="preserve"
            >
              <path
                fill="none"
                stroke={Primary_color._50}
                strokeWidth={0.075}
                strokeMiterlimit={10}
                points="61,44 61,55 1,55 1,15 61,15 61,26 "
                d="M2.288 1.65L2.288 2.063L0.037 2.063L0.037 0.563L2.288 0.563L2.288 0.975"
              />
              <path
                fill="none"
                stroke={Primary_color._50}
                strokeWidth={0.075}
                strokeMiterlimit={10}
                points="6,9 54,9 54,15 "
                d="M0.225 0.337L2.025 0.337L2.025 0.563"
              />
              <path
                fill="none"
                stroke={Primary_color._50}
                strokeWidth={0.075}
                strokeMiterlimit={10}
                d="M0.225 0.337a0.188 0.188 0 0 0 -0.188 0.188"
              />
              <path
                fill="none"
                stroke={Primary_color._50}
                strokeWidth={0.075}
                strokeMiterlimit={10}
                d="M1.617 0.975c-0.186 0 -0.337 0.151 -0.337 0.337a0.337 0.337 0 0 0 0.337 0.337H2.362V0.975H1.617z"
              />
              <path
                fill="none"
                stroke={Primary_color._50}
                strokeWidth={0.075}
                strokeMiterlimit={10}
                cx={44}
                cy={35}
                r={3}
                d="M1.762 1.313A0.112 0.112 0 0 1 1.65 1.425A0.112 0.112 0 0 1 1.537 1.313A0.112 0.112 0 0 1 1.762 1.313z"
              />
            </svg>
            </div>
            <Link className="block md:hidden absolute inset-0" to={"/cart"} />
          {/* </Popover.Button> */}
          {/* {<Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel className="hidden md:block absolute z-10 w-screen max-w-xs sm:max-w-md px-4 mt-3.5 -right-28 sm:right-0 sm:px-0"></Popover.Panel>
          </Transition>} */}
        </>
      )}
    </Popover>
  );
}
