
import { CustomLink } from "data/types";
import { getLocalStorage } from "network/helper";
import { getCompanyID } from "network/services/company";
import React, { useEffect, useState } from "react";

export interface WidgetFooterMenu {
  id: string;
  title: string;
  menus: CustomLink[];
}



const Footer: React.FC = () => {
  const [footerName, setFooterName] = useState<any>()
  const [company, setcompany] = useState<any>();

  useEffect(() => {
    const initCompanyDetails = async () => {
      let obj = {
        hostUrl:
          String(window.location.host).includes("localhost:3000")
            ? process.env.REACT_APP_DEFAULT_COMPANY_URL
            : String(window.location.host).split('/')[0],
      };
      const resp = await getCompanyID(obj);
      if (resp?.data?._id) {
        const company = resp.data;
        setcompany(company);
      }
    };
    initCompanyDetails();
  }, []);

  useEffect(() => {

    if (company?.isFooter) {
      setFooterName(company.footer)
    }
    else {
      setFooterName(undefined);
    }

  }, [company])

  return (
    <div className="nc-Footer relative py-2 lg:pt-8 lg:pb-10 border-t border-neutral-200 dark:border-neutral-700">
      <div className="container grid-cols-2 gap-y-10 gap-x-5 sm:gap-x-8 md:grid-cols-4 lg:grid-cols-5 lg:gap-x-10 ">
        <div className="text-center grid-cols-4 gap-5 col-span-2 md:col-span-4 lg:md:col-span-1 lg:flex lg:flex-col">
          <div className="col-span-2 md:col-span-1">{/* <Logo /> */}</div>
          <div style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'center' }}>
            {footerName != undefined && (
              <>
                <div>Copyright 2023  © 2024 <span className="text-primary-6000" style={{ fontWeight: '600' }} > {footerName}. </span> All Rights Reserved.</div>
                <div className="lg:block md:block hidden mx-2" >  {" "} | {" "} </div>
                <div>
                  <span>Powered by </span>
                  <a
                    target="blank"
                    style={{ textDecoration: "none", color: '#76ba5d', fontWeight: '600' }}
                  >
                    {footerName}
                  </a>
                </div>
              </>
            )}

          </div>

        </div>
      </div>
    </div>
  );
};

export default Footer;
