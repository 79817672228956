import { Helmet } from "react-helmet-async";
import HrDashboardCards from "./VoucherCards";

const HrDashboard = () => {
  return (
    <div className="nc-PageHome relative overflow-hidden">
      <Helmet>
        <title>My Vouchers</title>
      </Helmet>
      <div className="mt-12 mb-24 lg:mt-24">
        <HrDashboardCards />
      </div>
    </div>
  );
};

export default HrDashboard;
