import { PublicClientApplication } from '@azure/msal-browser';

export const getMsalConfig = (clientId, tenentId, redirectUrl) => {
  return {
    auth: {
      clientId: clientId,
      authority: `https://login.microsoftonline.com/${tenentId}`,
      redirectUri: redirectUrl,
      validateAuthority: true,
    },
    cache: {
      cacheLocation: 'localStorage',
      storeAuthStateInCookie: false,
    }
  };
};

export const loginRequest = {
  scopes: ["User.Read"]
};

export const initializeMsalInstance = (clientId, tenentId, redirectUrl) => {
  const msalConfig = getMsalConfig(clientId, tenentId, redirectUrl);
  return new PublicClientApplication(msalConfig);
};
