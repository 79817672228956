const PointsReducer = (
    state: number = 0,
    action: { type: any; payload: any }
  ) => {
    switch (action.type) {
        case "SET_POINTS":
            return action.payload;
        default:
            return state;
    }
  };
  
  export default PointsReducer;
  