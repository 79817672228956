import Button, { ButtonProps } from "shared/Button/Button";
import React from "react";

export interface ButtonDangerProps extends ButtonProps { }

const ButtonDanger: React.FC<ButtonDangerProps> = ({
    className = "",
    ...args
}) => {
    return (
        <Button
            className={`px-3 ttnc-ButtonDanger disabled:bg-opacity-90  border border-red-400 dark:bg-red-100 hover:bg-red-100 text-black dark:text-black shadow-xl ${className}`}
            {...args}
        />
    );
};

export default ButtonDanger;
