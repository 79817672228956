import { Dialog, Transition } from "@headlessui/react";
import { getCompanyID } from "network/services/company";
import React, { FC, Fragment, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import ButtonClose from "shared/ButtonClose/ButtonClose";

export interface ModalQuickViewProps {
    show: boolean;
    onCloseModalQuickView: () => void;
}

const initCompanyDetails = async (setDisclaimer: (text: string) => void) => {
    let obj = {
        hostUrl: String(window.location.host).includes("localhost:3000")
            ? process.env.REACT_APP_DEFAULT_COMPANY_URL
            : String(window.location.host).split("/")[0],
    };
    const resp = await getCompanyID(obj);
    if (resp?.data?._id) {
        const company = resp.data;
        console.log(company, "company modal ")
        setDisclaimer(company.disclaimer);
    }
};

const ModalQuickView: FC<ModalQuickViewProps> = ({
    show,
    onCloseModalQuickView,
}) => {
    const location = useLocation();
    const [disclaimer, setDisclaimer] = useState("");

    useEffect(() => {
        if (show) {
            initCompanyDetails(setDisclaimer);
        }
    }, [show]);

    return (
        <Transition appear show={show} as={Fragment}>
            <Dialog
                as="div"
                className="fixed inset-0 z-50"
                onClose={onCloseModalQuickView}
            >
                <div className="flex items-stretch md:items-center justify-center h-full text-center md:px-4">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 bg-black/40 dark:bg-black/70" />
                    </Transition.Child>

                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                    >
                        <div className="relative inline-flex xl:py-8 w-1/2 max-w-5xl max-h-full">
                            <div
                                className="flex-1 flex overflow-hidden max-h-full p-8 w-1/2 text-left align-middle transition-all transform lg:rounded-2xl bg-white 
                  dark:bg-neutral-900 dark:border dark:border-slate-700 dark:text-slate-100 shadow-xl"
                            >
                                <span className="absolute right-3 top-3 z-50">
                                    <ButtonClose onClick={onCloseModalQuickView} />
                                </span>
                                <div className="w-full">
                                    <h2 className="text-2xl font-semibold mb-4">Disclaimer</h2>
                                    <p>{disclaimer}</p>
                                </div>
                            </div>
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition>
    );
};

export default ModalQuickView;
