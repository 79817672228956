const CategoryReducer = (
  state = [],
  action: { type: any; payload: any }
) => {
  switch (action.type) {
    case "ADD_CATEGORY":
      const newItems = action.payload.filter(
        (newItem: any) =>
          !state.some((item: any) => item._id === newItem?._id)
      );
      return [...state, ...newItems];
    default:
      return state;
  }
};

export default CategoryReducer;
