import React, { FC, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import Input from "shared/Input/Input";
import { Link, useNavigate } from "react-router-dom";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { resetPasswordFromToken, verifyResetToken } from "network/services/account";
import toast from "react-hot-toast";
import { resetpassword } from "network/services/auth";
import { getLocalStorage } from "network/helper";
import SuccessModal from "components/SuccessModal";

export interface PageLoginProps {
  className?: string;
}

const PasswordChangePage: FC<PageLoginProps> = ({ className = "" }) => {
  const navigate = useNavigate();
  const [showSuccess, setShowSuccess] = useState(false)

  async function verifyToken() {
    const token = window.location.pathname.split("/")[2];
    const payload = {
      token,
      subdomain: getLocalStorage("company").subdomain
    }
    const res = await verifyResetToken(payload);
    const data = res && res.data;
    if (data) {
      toast.success("Password Reset Link Verified");
    } else {
      toast.error("Invalid Reset Token");
      navigate("/")
    }
  }

  const [password, setPassword] = useState({
    new_pass: "",
    confirm_pass: ""
  })

  async function updatePassword() {
    if (password.new_pass !== password.confirm_pass) {
      toast.error("Password Mismatch");
      return;
    }
    const payload = {
      token: window.location.pathname.split("/")[2],
      password: password.new_pass,
      subdomain: getLocalStorage("company").subdomain
    }
    const res = await resetPasswordFromToken(payload);
    console.log(res)
    if (res.errRes?.response?.data?.message) {
      setShowSuccess(true)
      toast.error("Required fields are not present in the body.");
      return;
    }
    if (res && res.data) {
      setShowSuccess(true)
      toast.success("Password Reset Link Verified");
      navigate("/login")
    }
  }

  useEffect(() => {
    verifyToken();
  }, [])

  return (
    <div className={`nc-PageLogin ${className}`} data-nc-id="PageLogin">
      <SuccessModal showModal={showSuccess} path="/login" successMessage="Password Reset Successful!!" redirectMessage="Redirecting to Login page..." />
      <div className="container mb-24 lg:mb-32">
        <h2 className="my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          Enter New Password
        </h2>
        <div className="max-w-md mx-auto space-y-6">

          {/* FORM */}
          <form className="grid grid-cols-1 gap-6" action="#" method="post">
            <label className="block relative">
              <span className="text-neutral-800 dark:text-neutral-200">
                New Password              </span>
              <Input
                type="password"
                name="new_pass"
                className="mt-1"
                value={password.new_pass}
                onChange={(e) => setPassword({ ...password, new_pass: e.target.value })}
              />
            </label>
            <label className="block">
              <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                Confirm  Password

              </span>
              <Input name="confirm_pass" type="text" className="mt-1" value={password.confirm_pass} onChange={(e) => setPassword({ ...password, confirm_pass: e.target.value })} />
            </label>
            <ButtonPrimary type="button" onClick={updatePassword}>Continue</ButtonPrimary>
          </form>
        </div>
      </div>
    </div>
  );
};

export default PasswordChangePage;
