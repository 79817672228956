import { Helmet } from "react-helmet-async";
import { useState, Fragment } from "react";
import { Link } from "react-router-dom";
import DynamicTable from "components/CustomTable";
import Heading from "components/Heading/Heading";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import { Popover, Transition } from "@headlessui/react";
import Checkbox from "shared/Checkbox/Checkbox";
import ButtonThird from "shared/Button/ButtonThird";
import Pagination from "shared/Pagination/Pagination";
import { hrVoucherTypeListing } from "network/services/HR";
import { useEffect } from "react";
import toast from "react-hot-toast";

const HrDashboard = () => {
  interface VoucherTypeItem {
    slno: string;
    id: string;
    voucherTypeName: string;
    connectedHR: string;
    connectedHRName: string; // New property
    connectedHREmail: string; // New property
    createdAt: string;
  }

  const [tableHeaders, setTableHeader] = useState([
    { name: "slno", key: "slno" },
    { name: "Name Of the voucher", key: "voucherTypeName" },
    { name: "Name of the POC", key: "connectedHRName" },
    { name: "Email of the POC", key: "connectedHREmail" },
    { name: "Requested Date", key: "createdAt" },
  ]);

  const ConvertDateObjToHumanReadableDateString = (dateStr: string) => {
    const date = new Date(dateStr);
    return date.toLocaleDateString('en-GB', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    })
  }

  const getVoucherTypesByUserId = async () => {
    try {
      const resp = await hrVoucherTypeListing();
      if (resp?.data) {
        let count = 1; // Initialize count variable
        // Assuming resp.data is an array of objects with properties matching TableItem interface
        const userData: VoucherTypeItem[] = resp.data.map((vouchertype: any) => ({
          slno: count++,
          id: vouchertype._id,
          voucherTypeName: vouchertype.voucherTypeName,
          connectedHR: vouchertype.connectedHR._id,
          connectedHRName: vouchertype.connectedHR.name,
          connectedHREmail: vouchertype.connectedHR.email,
          createdAt: ConvertDateObjToHumanReadableDateString(vouchertype.createdAt)
        }));
        console.log(userData)
        setTableData(userData);
      } else {
        toast.error(resp?.data.message);
      }
    } catch (error) {
      // Handle error
      console.log("Handled the error in try catch block")
      console.error(error);
    }
  };


  useEffect(() => {
    const response = getVoucherTypesByUserId();
  }, [])

  const [tableData, setTableData] = useState<VoucherTypeItem[]>([]);

  const [showSearchForm, setShowSearchForm] = useState(false);

  const [searchQuery, setSearchQuery] = useState('');

  // Function to handle search input change
  const handleSearchInputChange = (event: any) => {
    setSearchQuery(event.target.value);
  };

  // Function to filter table data based on search query
  const filterTableData = (data: any) => {
    return data.filter((item: any) =>
      Object.values(item).some(
        (value) =>
          value &&
          value.toString().toLowerCase().includes(searchQuery.toLowerCase())
      )
    );
  };

  const renderMagnifyingGlassIcon = () => {
    return (
      <svg
        width={22}
        height={22}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11.5 21C16.7467 21 21 16.7467 21 11.5C21 6.25329 16.7467 2 11.5 2C6.25329 2 2 6.25329 2 11.5C2 16.7467 6.25329 21 11.5 21Z"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M22 22L20 20"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  };

  const renderSearchForm = () => {
    return (
      <div className="flex-1 w-full py-2 text-slate-900 dark:text-slate-100">
        <div className="bg-slate-50 dark:bg-slate-800 flex items-center space-x-1.5 px-5 h-full rounded">
          {renderMagnifyingGlassIcon()}
          <input
            type="text"
            placeholder="Search by name of the voucher"
            className="border-none bg-transparent focus:outline-none focus:ring-0 w-full text-base"
            autoFocus
            value={searchQuery} // Connect value to search query state
            onChange={handleSearchInputChange} // Handle input change
          />
          {/* <button type="button" onClick={() => setShowSearchForm(false)}>
            <XMarkIcon className="w-5 h-5" />
          </button> */}
        </div>
      </div>
    );
  };


  const renderXClear = () => {
    return (
      <span className="flex-shrink-0 w-4 h-4 rounded-full bg-primary-500 text-white flex items-center justify-center ml-3 cursor-pointer">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-3 w-3"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fillRule="evenodd"
            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
            clipRule="evenodd"
          />
        </svg>
      </span>
    );
  };


  return (
    <div className="container">
      <Helmet>
        <title>Hr-Vouchers</title>
      </Helmet>
      <div className="mb-12 sm:mb-16">
        <div className="block mt-3 sm:mt-5 text-xs sm:text-sm font-medium text-slate-700 dark:text-slate-400">
          <Link to={"/hr-dashboard"} className="">
            My Menu
          </Link>
          <span className="text-xs mx-1 sm:mx-1.5">/</span>
          <span className="underline">View Vouchers</span>
        </div>
      </div>
      <Heading children="Track Vouchers" />
      <div className="mt-6 rounded mb-24 lg:mt-4 border border-slate ">
        <div className="bg-white rounded ">
          <div className=" flex items-center justify-between pb-4 m-4 pt-4">
            <div className="flex w-full  mr-5 items-center">
              {renderSearchForm()}
            </div>
            <div className="">
              {/* <ButtonPrimary type="button">Download</ButtonPrimary> */}
            </div>
          </div>
          <DynamicTable path="/hr-vouchers-details" tableData={filterTableData(tableData)} tableHeader={tableHeaders} />
          <div className=" pb-4 m-4 pt-4 flex flex-col mt-12 lg:mt-4 space-y-5 sm:space-y-0 sm:space-x-3 sm:flex-row sm:justify-end sm:items-center">
            {/* <ButtonPrimary loading>Show me more</ButtonPrimary> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default HrDashboard;
