

export const verifyAddress = (addressDetails: {
    firstName: string;
    lastName: string;
    email: string;
    phoneNo: string;
    address: string;
    district: string;
    city: string;
    state: string;
    country: string;
    pincode: string;
    altContact: string;
}) => {
    const alpha_regex = new RegExp(/^[A-Za-z ]+$/);    
    const numeric_regex = new RegExp(/^[0-9]+$/);
    const email_regex = new RegExp(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/);
    const city_regex = new RegExp(/^([a-zA-Z \u0080-\u024F]+(?:. |-| |'))*[a-zA-Z\u0080-\u024F]*$/)
    const phone_number_regex = new RegExp(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/)

    if(addressDetails.firstName.length == 0 || !alpha_regex.test(addressDetails.firstName)) return "Please Enter Valid First Name"
    if(addressDetails.lastName.length == 0  || addressDetails.lastName.split(" ").join("").length == 0) return "Please Enter Valid Last Name"
    if(addressDetails.email.length == 0 || !email_regex.test(addressDetails.email)) return "Please Enter Valid Email"
    if(addressDetails.phoneNo.length == 0  || !phone_number_regex.test(addressDetails.phoneNo)) return "Please Enter Valid Phone Number"
    if(addressDetails.altContact && !phone_number_regex.test(addressDetails.altContact)) return "Please Enter Valid Alternate Number"
    if(addressDetails.address.length == 0) return "Please Enter Valid Address"
    if(addressDetails.country.length == 0  || !alpha_regex.test(addressDetails.country)) return "Please Enter Valid Country"
    if(addressDetails.state.length == 0  || !alpha_regex.test(addressDetails.state)) return "Please Enter Valid State"
    if(addressDetails.city.length == 0  || !city_regex.test(addressDetails.city)) return "Please Enter Valid City"
    if(addressDetails.pincode.length < 6 || !numeric_regex.test(addressDetails.pincode)) return "Please Enter Valid Postal-Code"
    return null
}