import React, { FC, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import SocialsList from "shared/SocialsList/SocialsList";
import Label from "components/Label/Label";
import Input from "shared/Input/Input";
import Textarea from "shared/Textarea/Textarea";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import SectionPromo1 from "components/SectionPromo1";
import { getLocalStorage } from "network/helper";
import Select from "shared/Select/Select";
import { contactSupport } from "network/services/support";
import { toast } from "react-hot-toast";
import Prev from "shared/NextPrev/Prev";
import { useNavigate } from "react-router-dom";

export interface PageContactProps {
  className?: string;
}

const info = [
  {
    title: "Technical Query / Shipping query",
    email: "GL.example@example.com",
    phone: "87872387XX",
  },
  {
    title: "Order Related Query",
    email: `GL.example@example.com`,
    phone: "847584757623XX",
  },
];

const PageContact: FC<PageContactProps> = ({ className = "" }) => {
  const navigate = useNavigate();

  const [supportDetails, setSupportDetails] = useState<any>();
  const [values, setValues] = useState({
    fullName: "",
    email: "",
    subject: "",
    message: "",
  });

  useEffect(() => {
    setSupportDetails(getLocalStorage("company")?.supportDetails);
  }, []);

  const handleInputChange = (e: any) => {
    let { name, value } = e.target;
    let tempValue = value.trim()
    if(value[value.length - 1] === " ")
      tempValue += " "
    if(name === "fullName") {
      const regex = /^[a-zA-Z ]+$/
      if(!regex.test(tempValue) && tempValue != "") return
    }

    setValues({ ...values, [name]: tempValue});
  };

  const checkValues = () => {
    if(!values.fullName) return "Please Enter Valid Name"
    if(!values.email) return "Please Enter Valid Email"
    if(!values.subject) return "Please Select A Subject"
    if(!values.message) return "Please Enter Valid Message"
    return null
  }

  const handleSubmit = async () => {
    const error = checkValues()
    if(error) {
      toast.error(error)
      return
    }
    const company = getLocalStorage('company')
    const res = await contactSupport({
      ...values,
      email: values.email.toLowerCase(),
      companyId: company._id
    })
    if(res.errRes && res.errRes.data && res.errRes.data.message) {
      toast.error(res.errRes.data.message)
      return
    }
    if(res.data.message == "Ticket created") {
      toast.success(res.data.message)
      setValues({
        fullName: "",
        email: "",
        subject: "",
        message: "",
      })
    }
  }


  return (
    <div
      className={`nc-PageContact overflow-hidden ${className} relative`}
      data-nc-id="PageContact"
    >
      <Helmet>
        <title>Help Desk</title>
      </Helmet>
      <div className="container max-w-7xl absolute left-1/2 -translate-x-1/2 top-20">
        <Prev
          className="z-10 !text-slate-700"
          btnClassName="w-12 h-12 hover:border-slate-400 dark:hover:border-slate-400"
          svgSize="w-6 h-6"
          onClickPrev={() => navigate(-1)}
        />
      </div>
      <div className=" mb-32">
        <div></div>
        <h2
          style={{ flexDirection: "column" }}
          className="my-20 flex items-center  text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center"
        >
          <svg
            width="50"
            height="50"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M11.97 22C17.4928 22 21.97 17.5228 21.97 12C21.97 6.47715 17.4928 2 11.97 2C6.44715 2 1.97 6.47715 1.97 12C1.97 17.5228 6.44715 22 11.97 22Z"
              stroke="currentColor"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M12 16.5C14.4853 16.5 16.5 14.4853 16.5 12C16.5 9.51472 14.4853 7.5 12 7.5C9.51472 7.5 7.5 9.51472 7.5 12C7.5 14.4853 9.51472 16.5 12 16.5Z"
              stroke="currentColor"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M4.89999 4.92993L8.43999 8.45993"
              stroke="currentColor"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M4.89999 19.07L8.43999 15.54"
              stroke="currentColor"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M19.05 19.07L15.51 15.54"
              stroke="currentColor"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M19.05 4.92993L15.51 8.45993"
              stroke="currentColor"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>{" "}
          <span className="ml-2">Help Desk</span>
        </h2>
        <div className="container max-w-7xl mx-auto">
          <div className="flex-shrink-0 grid grid-cols-1 md:grid-cols-2 gap-12 ">
            <div className="max-w-sm space-y-8">
              {supportDetails &&
                supportDetails.map((item: any, index: any) => (
                  <div key={index}>
                    <h3 className="uppercase font-semibold text-sm dark:text-neutral-200 tracking-wider">
                      {item.fullName}
                    </h3>
                    <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
                      Email : <a href="mailto:{item.email}">{item.email}</a>
                    </span>
                    <span className="block mt-1 text-neutral-500 dark:text-neutral-400">
                      Phone :{" "}
                      <a href={`tel:+91${item?.phone}`}>{item?.phone}</a>
                    </span>
                    <span className="block mt-1 text-neutral-500 dark:text-neutral-400">
                      Availability : {item?.availability}
                    </span>
                  </div>
                ))}
            </div>
            <div>
              <form className="grid grid-cols-1 gap-6">
                <label className="block">
                  <Label>Full name</Label>

                  <Input
                    onChange={handleInputChange}
                    placeholder="Example Doe"
                    value={values.fullName}
                    type="text"
                    name="fullName"
                    className="mt-1"
                  />
                </label>
                <label className="block">
                  <Label>Email address</Label>

                  <Input
                    type="email"
                    name="email"
                    value={values.email}
                    onChange={handleInputChange}
                    placeholder="example@example.com"
                    className="mt-1"
                  />
                </label>
                <label className="block">
                  <Label>Subject</Label>

                  <Select onChange={handleInputChange} value={values.subject} name="subject" className="mt-1">
                    <option value="">Select Issue Subject</option>
                    <option value={"Technical"}>Technical</option>
                    <option value={"Order related"}>Order related</option>
                    <option value={"Others"}>Others</option>
                  </Select>
                </label>
                <label className="block">
                  <Label>Message</Label>

                  <Textarea value={values.message} onChange={handleInputChange}  name='message' className="mt-1" rows={6} />
                </label>
                <div>

                  <ButtonPrimary type="button" onClick={handleSubmit}>Send Message</ButtonPrimary>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageContact;
