const WishlistReducer = (
  state: any = [],
  action: { type: any; payload: any }
) => {
  switch (action.type) {
    case "ADD_WISH_LIST":
      if (!state.includes(action.payload)) {
        return [...state, action.payload];
      } else {
        return state;
      }
    case "DELETE_WISH_LIST":
      const productIdToDelete = action.payload;
      const updatedStateAfterDelete = state.filter((item: any) => {
        return item !== productIdToDelete;
      });
      return updatedStateAfterDelete;

    default:
      return state;
  }
};

export default WishlistReducer;
