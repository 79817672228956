export const ADD_MODAL_PRODUCT = "ADD_MODAL_PRODUCT";
export const DELETE_MODAL_PRODUCT = "DELETE_MODAL_PRODUCT";

export const ADD_MODAL_VIEW_PRODUCT_ACTION = (data: any) => {
  return {
    type: ADD_MODAL_PRODUCT,
    payload: data,
  };
};


export const DELETE_MODAL_VIEW_PRODUCT_ACTION = (data: any) => {
    return {
      type: DELETE_MODAL_PRODUCT,
      payload: data,
    };
  };
  