import React, { useEffect, useId, useState } from "react";
import Heading from "../../components/Heading/Heading";
import img1 from "images/collections/giftBox.png";
import img2 from "images/collections/5.png";
import img3 from "images/collections/4.png";
import img4 from "images/collections/3.png";
import CardCategory3, {
  CardCategory3Props,
} from "../../components/CardCategories/CardCategory3";
import Glide from "@glidejs/glide";
import { getUserVouchers } from "network/services/account";

export const CATS_DISCOVER: CardCategory3Props[] = [
  {
    name: "Welcome Kit",
    desc: `Redeem your new joinee welcome kit`,
    featuredImage: img1,
    color: "#faebc8",
  },
  {
    name: "Digital gift cards",
    desc: "Give the gift <br /> of choice",
    featuredImage: img2,
    color: "#faebc8",
  },
  {
    name: "Sale collection",
    desc: "Up to <br /> 80% off retail",
    featuredImage: img3,
    color: "#faebc8",
  },
  {
    name: "Sale collection",
    desc: "Up to <br /> 80% off retail",
    featuredImage: img4,
    color: "#faebc8",
  },
];

const DiscoverMoreSlider = ({ gridClassName = "grid-cols-1 md:grid-cols-2 xl:grid-cols-2" }) => {
  const id = useId();
  // const UNIQUE_CLASS = "glidejs" + id.replace(/:/g, "_");
  const [vouchers, setVouchers] = useState<Array<any> | null>(null)
  const [claimedVouchers, setClaimedVouchers] = useState<Array<any> | null>(null)

  const fetchVouchers = async () => {
    const res = await getUserVouchers();
    if(res.errRes) return
    setVouchers(res.data.filter((item: any) => !item.isClaimed))
    setClaimedVouchers(res.data.filter((item: any) => item.isClaimed))
  }

  useEffect(() => {
    fetchVouchers()
  }, [])

  return (vouchers && vouchers.length > 0)?(
    <div className={`container `}>
      <Heading
        className="mb-12 lg:mb-14 text-neutral-900 dark:text-neutral-50 nc-p-r-container "
        isCenter={!vouchers}
        desc=""
        rightDescText=" "
      >
        {vouchers ? ("My Vouchers"): ("No Vouchers Available")}
      </Heading>
      {
        vouchers != null && (
          <div className="">
            <ul className={`grid gap-4 md:gap-7 ${gridClassName}`}>
              {vouchers.filter((item) => (item.voucherTypeId.isActive)).map((item, index) => (
                <li key={index} className={``}>
                  <CardCategory3
                    path={`/my-voucher-details/${item._id}?combo=${item.voucherTypeId.mechanism == "COMBO"}`}
                    name={(item.voucherTypeId.voucherCardImage)?"":item.voucherTypeId.voucherTypeName}
                    desc={(item.voucherTypeId.voucherCardImage)?"":item.voucherTypeId.tagline}
                    state={item}
                    backgroundImage={item.voucherTypeId.voucherCardImage}
                    color={item.color}
                  />
                </li>
              ))}
            </ul>
          </div>
        )
      }
      {
        claimedVouchers != null && (
          <div className="mt-24">
            <Heading 
              className="mb-12 lg:mb-14 text-neutral-900 dark:text-neutral-50 nc-p-r-container "
            >
              Claimed Vouchers
            </Heading>
            <div className="">
              <ul className={`grid gap-4 md:gap-7 ${gridClassName}`}>
                {claimedVouchers.filter((item) => (item.voucherTypeId.isActive)).map((item, index) => (
                  <li key={index} className={``}>
                    <CardCategory3
                      buttonTitle="Claimed"
                      path={`/my-voucher-details/${item._id}?combo=${item.voucherTypeId.mechanism == "COMBO"}`}
                      name={(item.voucherTypeId.voucherCardImage)?"":item.voucherTypeId.voucherTypeName}
                      desc={(item.voucherTypeId.voucherCardImage)?"":item.voucherTypeId.tagline}
                      state={item}
                      backgroundImage={item.voucherTypeId.voucherCardImage}
                      color={"#8d8"}
                    />
                  </li>
                ))}
              </ul>
            </div>
          </div>
        )
      }
    </div>
  ): (
    <div className="text-center text-3xl xl:text-4xl font-semibold">
      No Available Vouchers
    </div>
  )
};

export default DiscoverMoreSlider;
