import MyRouter from "routers/index";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { getLocalStorage, isAuth, setLocalStorage } from "network/helper";
import { useEffect } from "react";
import { getCompanyID } from "network/services/company";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { getUserDetails } from "network/services/account";
import { setCompanyStyles } from "utils/setCompanyStyles";

function App() {

  async function fetchDetails() {
    let obj = {
      hostUrl:
        String(window.location.host).includes("localhost:3000")
          ? process.env.REACT_APP_DEFAULT_COMPANY_URL
          : String(window.location.host).split('/')[0],
    };

    try {
      const response = await getCompanyID(obj);

      const userDetails = await getUserDetails()

      const userTokenDetails = window.localStorage.getItem('user')
      const formattedUserTokenDetails = JSON.parse(String(userTokenDetails))

      if (response?.data?._id) {
        setLocalStorage("company", response?.data);
        setLocalStorage("user_details", userDetails?.data);
        setLocalStorage("user", { ...formattedUserTokenDetails, isHR: userDetails?.data?.isHR });
      } else {
        toast.error("Something went wrong");
      }
    } catch {
      toast.error("Something went wrong");
    }
  }

  useEffect(() => {
    const companyDetails = getLocalStorage("company")
    if (companyDetails)
      setCompanyStyles(document, companyDetails)

    if (isAuth()) {
      fetchDetails()
    }
  });

  return (
    <HelmetProvider>
      <Helmet>
        <title>Giftlinks</title>
        <meta name="Giftlinks" content="giftlinks-eCommerce " />
        <link rel="icon" href={getLocalStorage("company")?.logo} />
      </Helmet>
      <div className="bg-white text-base dark:bg-slate-900 text-slate-900 dark:text-slate-200">
        <MyRouter />
      </div>
    </HelmetProvider>
  );
}

export default App;
