import ProductCard from "components/ProductCard";
import { PRODUCTS } from "data/data";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import CommonLayout from "./CommonLayout";
import { getUserWishListDetails } from "network/services/account";
import { ADD_WISH_LIST_ACTION } from "../../redux/actions/wishlist";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

const AccountSavelists = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [wishListProductData, setWishListProductData] = useState<any>([]);
  const WishListSelector: any = useSelector((state: any) => state?.Wish__list);
  const getWishListDetailsFromAPI = async () => {
    try {
      const response = await getUserWishListDetails();
      if (response?.data.message !== 'Wishlist is empty') {
        setWishListProductData(response?.data);
        response?.data.map((item: any) => {
          dispatch(ADD_WISH_LIST_ACTION(item?.product?._id));
        });
      }else{
        setWishListProductData([])
      }
    } catch (error) {}
  };

  useEffect(() => {
    getWishListDetailsFromAPI();
  }, [WishListSelector]);
  
  const renderSection1 = () => {
    return (
      <div>
        {wishListProductData.length >= 1 && (
          <div className="space-y-10 sm:space-y-12">
            <div>
              <h2 className="text-2xl sm:text-3xl font-semibold">
                List of saved products
              </h2>
            </div>

            <div className="grid grid-cols-1 gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-3 ">
              {wishListProductData.length >= 1 &&
                wishListProductData.map((item: any) => (
                  <ProductCard key={item._id} data={item?.product} />
                ))}
            </div>
          </div>
        )}
        {wishListProductData.length<=0 && (
          <div className="space-y-10 sm:space-y-12">
            <div>
              <p className="text-2xl sm:text-3xl font-semibold">
                No items are added to saved lists
              </p>
            </div>
          </div>
        )}
      </div>
    );
  };

  return (
    <div>
      <CommonLayout>{renderSection1()}</CommonLayout>
    </div>
  );
};

export default AccountSavelists;
