import React, {
  FC,
  useEffect,
  useId,
  useReducer,
  useRef,
  useState,
} from "react";
import Heading from "components/Heading/Heading";
import Glide from "@glidejs/glide";
import { Product, PRODUCTS } from "data/data";
import CategoryCard from "components/CategoryCard";
import { getListCategory as getCategoryFromAPI } from "network/services/product";
import { useSelector } from "react-redux";

export interface SectionSliderProductCardProps {
  className?: string;
  itemClassName?: string;
  heading?: string;
  headingFontClassName?: string;
  headingClassName?: string;
  subHeading?: string;
}

const CategoryPage: FC<SectionSliderProductCardProps> = ({
  className = "",
  itemClassName = "",
  headingFontClassName,
  headingClassName,
  heading,
  subHeading = "",
}) => {
  const selector: any = useSelector<any>((state) => state.Category);

  const sliderRef = useRef(null);
  const id = useId();
  const UNIQUE_CLASS = "glidejs" + id.replace(/:/g, "_");


  useEffect(() => {
    if (!sliderRef.current) {
      return () => { };
    }

    // @ts-ignore
    const OPTIONS: Glide.Options = {
      perView: 4,
      gap: 32,
      bound: true,
      breakpoints: {
        1280: {
          perView: 4 - 1,
        },
        1024: {
          gap: 20,
          perView: 4 - 1,
        },
        768: {
          gap: 20,
          perView: 4 - 2,
        },
        640: {
          gap: 20,
          perView: 1.5,
        },
        500: {
          gap: 20,
          perView: 1.3,
        },
      },
    };

    let slider = new Glide(`.${UNIQUE_CLASS}`, OPTIONS);
    slider.mount();
    return () => {
      slider.destroy();
    };
  }, [sliderRef, UNIQUE_CLASS]);



  return (
    <div className={`nc-SectionSliderProductCard ${className}`}>
      <div className={`${UNIQUE_CLASS} flow-root`} ref={sliderRef}>
        <Heading
          className={headingClassName}
          fontClass={headingFontClassName}
          rightDescText={subHeading}
          hasNextPrev
        >
          {heading || `Category`}
          {!subHeading && (
            <div
              className={`text-neutral-500 mt-3  dark:text-neutral-400 text-xl md:text-2xl `}
            >
              Find the best products
            </div>
          )}
        </Heading>
        <div className="glide__track" data-glide-el="track">
          <ul className="glide__slides">
            {selector &&
              selector.map((item: any, index: any) => (
                <li key={index} className={`glide__slide ${itemClassName}`}>
                  <CategoryCard data={item} />
                </li>
              ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default CategoryPage;
