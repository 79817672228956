import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import HeaderLogged from "components/Header/HeaderLogged";
import Header from "components/Header/Header";
import { isAuth } from "network/helper";

const SiteHeader = () => {
  const location = useLocation();
  const [isHr, setIsHr] = useState(false);

  useEffect(() => {
    setIsHr(isAuth()?.isHR && location.pathname.includes("/hr-"));
  }, [location]);
  return <div>{isHr ? <Header /> : isAuth() ? <HeaderLogged /> : null}</div>;
};
export default SiteHeader;
