function hexToRgb(hex: string): string {
	const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
	return result 
		? `${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ${parseInt(result[3], 16)}`
		: "0, 0, 0";
}

export const setCompanyStyles = (document: any, companyDetails: any) => {
	document.documentElement.style.setProperty('--c-primary-50', hexToRgb(companyDetails?.primary50));
	document.documentElement.style.setProperty('--c-primary-100', hexToRgb(companyDetails?.primary100));
	document.documentElement.style.setProperty('--c-primary-200', hexToRgb(companyDetails?.primary200));
	document.documentElement.style.setProperty('--c-primary-300', hexToRgb(companyDetails?.primary300));
	document.documentElement.style.setProperty('--c-primary-400', hexToRgb(companyDetails?.primary400));
	document.documentElement.style.setProperty('--c-primary-500', hexToRgb(companyDetails?.primary500));
	document.documentElement.style.setProperty('--c-primary-600', hexToRgb(companyDetails?.primary600));
	document.documentElement.style.setProperty('--c-primary-700', hexToRgb(companyDetails?.primary700));
	document.documentElement.style.setProperty('--c-primary-800', hexToRgb(companyDetails?.primary800));
	document.documentElement.style.setProperty('--c-primary-900', hexToRgb(companyDetails?.primary900));

	document.documentElement.style.setProperty('--c-secondary-50', hexToRgb(companyDetails?.secondary50));
	document.documentElement.style.setProperty('--c-secondary-100', hexToRgb(companyDetails?.secondary100));
	document.documentElement.style.setProperty('--c-secondary-200', hexToRgb(companyDetails?.secondary200));
	document.documentElement.style.setProperty('--c-secondary-300', hexToRgb(companyDetails?.secondary300));
	document.documentElement.style.setProperty('--c-secondary-400', hexToRgb(companyDetails?.secondary400));
	document.documentElement.style.setProperty('--c-secondary-500', hexToRgb(companyDetails?.secondary500));
	document.documentElement.style.setProperty('--c-secondary-600', hexToRgb(companyDetails?.secondary600));
	document.documentElement.style.setProperty('--c-secondary-700', hexToRgb(companyDetails?.secondary700));
	document.documentElement.style.setProperty('--c-secondary-800', hexToRgb(companyDetails?.secondary800));
	document.documentElement.style.setProperty('--c-secondary-900', hexToRgb(companyDetails?.secondary900));

	document.documentElement.style.setProperty('--c-neutral-50', hexToRgb(companyDetails?.neutral50));
	document.documentElement.style.setProperty('--c-neutral-100', hexToRgb(companyDetails?.neutral100));
	document.documentElement.style.setProperty('--c-neutral-200', hexToRgb(companyDetails?.neutral200));
	document.documentElement.style.setProperty('--c-neutral-300', hexToRgb(companyDetails?.neutral300));
	document.documentElement.style.setProperty('--c-neutral-400', hexToRgb(companyDetails?.neutral400));
	document.documentElement.style.setProperty('--c-neutral-500', hexToRgb(companyDetails?.neutral500));
	document.documentElement.style.setProperty('--c-neutral-600', hexToRgb(companyDetails?.neutral600));
	document.documentElement.style.setProperty('--c-neutral-700', hexToRgb(companyDetails?.neutral700));
	document.documentElement.style.setProperty('--c-neutral-800', hexToRgb(companyDetails?.neutral800));
	document.documentElement.style.setProperty('--c-neutral-900', hexToRgb(companyDetails?.neutral900));
}