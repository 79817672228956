import { Disclosure } from "@headlessui/react";
import { MinusIcon, PlusIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { getLocalStorage } from "network/helper";
import { getFAQ } from "network/services/company";
import React from "react";
import { FC, useEffect, useState } from "react";
import toast from "react-hot-toast";

interface Props {
  panelClassName?: string;
}

const AccordionInfo: FC<Props> = ({
  panelClassName = "p-4 pt-3 last:pb-0 text-slate-600 text-sm dark:text-slate-300 leading-6",
}) => {
  const [selectedFAQ, setSelectedFAQ] = useState(-1);
  const [allFaq, setAllFaq] = useState([])
  const [company, setCompany] = useState({
    _id: getLocalStorage("company")?._id,
  });
  const [faq, setFaq] = useState<any>([]);
  const inputRef = React.createRef<HTMLInputElement>();
  const handleFQAOpen = (id: number) => {
    if (id === selectedFAQ) {
      setSelectedFAQ(-1);
    } else {
      setSelectedFAQ(id);
    }
  };

  const getFaq = async () => {
    try {
      const response = await getFAQ(company!._id);
      if(response?.data.message) {
        toast.error(response.data.message)
        return
      }
      if (response?.data) {
        let temp: any = [];
        response?.data.map((item: any, index: number) => {
          let tempObj = {
            id: index,
            name: item?.question,
            content: item?.answer,
          };
          temp.push(tempObj);
        });
        setAllFaq(temp)
        setFaq(temp);
      } else {
        toast.error("Something went wrong");
      }
    } catch (error) {
      toast.error("Something went wrong");
    }
  };

  useEffect(() => {
    getFaq();
    setCompany(getLocalStorage("company"));
  }, []);

  const renderMagnifyingGlassIcon = () => {
    return (
      <svg
        width={22}
        height={22}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11.5 21C16.7467 21 21 16.7467 21 11.5C21 6.25329 16.7467 2 11.5 2C6.25329 2 2 6.25329 2 11.5C2 16.7467 6.25329 21 11.5 21Z"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M22 22L20 20"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  };
  const renderSearchForm = () => {
    return (
      <>
        <div className="bg-slate-50 dark:bg-slate-800 flex items-center space-x-1.5 px-5 rounded">
          {renderMagnifyingGlassIcon()}
          <input
            ref={inputRef}
            type="text"
            placeholder="Search your query"
            className="border-none bg-transparent focus:outline-none focus:ring-0 w-full text-base"
            autoFocus
            onChange={(e) => {
              const inputValue = e.target.value.toLowerCase();
              if (inputValue === "") {
                getFaq();
              } else {
                const filteredFaq = allFaq.filter(
                  (item: any) =>
                    item.name && item.name.toLowerCase().includes(inputValue)
                );
                setFaq(filteredFaq);
              }
            }}
          />
        </div>
        <input type="submit" hidden value="" />
      </>
    );
  };
  return (
    <div className="w-full rounded-2xl space-y-2.5">
      <div className="my-6 border border-slate w-100 d-flex align-items-center justify-content-center">
        {renderSearchForm()}
      </div>

      {faq &&
        faq.map((item: any, index: any) => {
          return (
            <Disclosure key={index}>
              <>
                <Disclosure.Button
                  onClick={() => handleFQAOpen(item?.id)}
                  className="flex items-center justify-between w-full px-4 py-2 font-medium text-left bg-slate-100/80 hover:bg-slate-200/60 dark:bg-slate-800 dark:hover:bg-slate-700 rounded-lg focus:outline-none focus-visible:ring focus-visible:ring-slate-500 focus-visible:ring-opacity-75 "
                >
                  <span>
                    {index + 1} . {item.name}
                  </span>
                  {selectedFAQ !== item?.id ? (
                    <PlusIcon className="w-4 h-4 text-slate-600 dark:text-slate-400" />
                  ) : (
                    <MinusIcon className="w-4 h-4 text-slate-600 dark:text-slate-400" />
                  )}
                </Disclosure.Button>
                <Disclosure.Panel
                  className={panelClassName}
                  as="div"
                  dangerouslySetInnerHTML={{ __html: item.content }}
                ></Disclosure.Panel>
              </>
            </Disclosure>
          );
        })}
    </div>
  );
};

export default AccordionInfo;
